import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PAGES, { ROUTES } from "../constants/pages";
import { useAuthSelector } from "../store/authSlice";

const authRoutes = [
  PAGES.login,
  PAGES.forgotPassword,
  PAGES.otp,
  PAGES.resetPassword,
];

const AuthRoute = ({ children }) => {
  const { authToken, verified } = useAuthSelector();
  const location = useLocation();

  const isAuthRoute = authRoutes.includes(location.pathname);

  if (ROUTES.includes(location.pathname)) {
    if (authToken && verified) {
      if (isAuthRoute) {
        return <Navigate to={PAGES.dashboard} />;
      }
    }
    return children;
  } else {
    <Navigate to='*' />;
  }
};

export default AuthRoute;