import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PAGES, { ROUTES } from "../constants/pages";
import { useAuthSelector } from "../store/authSlice";

const ProtectedRoute = ({ children }) => {
  const { authToken, verified } = useAuthSelector();
  const location = useLocation();

  if (ROUTES.includes(location.pathname)) {
    if (!authToken || !verified) {
      return <Navigate to={PAGES.login} />;
    }
    return children;
  } else {
    <Navigate to={"*"} />;
  }
};

export default ProtectedRoute;