import * as Yup from "yup";
const basicInfoSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^\+?\d{10,15}$/, "Phone number is not valid")
    .required("Phone number is required"),
});

const passwordSchema = Yup.object({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm Password is required"),
});

const validationSchemaWithEmail = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string()
    .oneOf(["male", "female"], "Select a valid gender")
    .required("Gender is required"),
  interestedIn: Yup.string()
    .oneOf(["men", "women"], "Select a valid option")
    .required("Interested In is required"),
  dob: Yup.date()
    .max(
      new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000),
      "Must be at least 18 years old"
    )
    .required("Date of birth is required"),
  location: Yup.string()
    .required("Location is required")
    .test(
      "location-validity",
      "Please select a valid location.",
      function (value) {
        const { latitude, longitude } = this.parent;
        return latitude && longitude;
      }
    ),
  bio: Yup.string().required("Bio is required"),
  status: Yup.string()
    .oneOf(["0", "1"], "Select a valid status")
    .required("Status is required"),
  selectedCategories: Yup.array().min(1, "At least one category is required"),
});


const validationSchemaWithPhone = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phoneNumber: Yup.string()
  .matches(
    /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,4}\)?[\s-]?)?[\d\s-]{7,14}$/,
    "Phone number is not valid"
  )
    .required("Phone number is required"),
  gender: Yup.string()
    .oneOf(["male", "female"], "Select a valid gender")
    .required("Gender is required"),
  interestedIn: Yup.string()
    .oneOf(["men", "women"], "Select a valid option")
    .required("Interested In is required"),
  dob: Yup.date()
    .max(
      new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000),
      "Must be at least 18 years old"
    )
    .required("Date of birth is required"),
  location: Yup.string()
    .required("Location is required")
    .test(
      "location-validity",
      "Please select a valid location.",
      function (value) {
        const { latitude, longitude } = this.parent;
        return latitude && longitude;
      }
    ),
  bio: Yup.string().required("Bio is required"),
  status: Yup.string()
    .oneOf(["0", "1"], "Select a valid status")
    .required("Status is required"),
  selectedCategories: Yup.array().min(1, "At least one category is required"),
});


const taxSchema = Yup.object().shape({
  tax: Yup.number()
    .required("Please enter tax value")
    .test('is-valid', 'Tax must be zero or a positive number', function (value) {
      if (value < 0) {
        return false; 
      }
      return true; 
    }),
});

export { basicInfoSchema, passwordSchema, validationSchemaWithEmail , validationSchemaWithPhone,taxSchema};
