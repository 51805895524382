import React from "react";
import { Dropdown, Breadcrumb, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../assets/img/dummy-user.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../../store/authSlice";
import PAGES from "../../constants/pages";
import config from "../../constants/config";

const Header = ({ title }) => {
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Handle logout
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate(PAGES.login, { replace: true });
  };

  return (
    <header className="header">
      <Row>
        <Col xs={6} className="align-self-center">
          <div className="mainbreadcrumb d-none d-md-block">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Pages</Breadcrumb.Item>
              <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>
            {/* <h1>{title}</h1> */}
          </div>
        </Col>
        <Col xs={6} className="text-end">
          <div
            className="headerright"
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="p-0"
                variant={null}
              >
                <img alt="" src={user?.profile_url ? `${config.IMAGES_URL}/${user?.profile_url}` : Avatar} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={PAGES.profileSettings}>
                  Profile Settings
                </Dropdown.Item>
                <Dropdown.Item as={Link} onClick={handleLogout} to="/">
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
