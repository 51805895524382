import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from 'react-router-dom';
import logodark from "../assets/img/logodark.svg";
import { Icon } from '@iconify/react';
import PAGES from "../../constants/pages";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    document.body.classList.toggle("no-scroll", sidebarOpen);
  }, [sidebarOpen]);

  const handleNavLinkClick = () => {
    setSidebarOpen(false);
    setOpenSubmenu(null);
  };

  const handleSubmenuClick = (id) => {
    setOpenSubmenu(openSubmenu === id ? null : id);
  };

  const isSubmenuItemActive = (submenu) => {
    return submenu.some(sub => location.pathname.startsWith(sub.link));
  };

  const pages = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      link: PAGES.dashboard,
      icon: 'mdi:view-grid-outline',
    },
    {
      id: 'Users',
      title: 'Users',
      link: PAGES.users,
      icon: 'lucide:users',
    },
    {
      id: 'Adventures',
      title: 'Adventures',
      link: 'javascript:void(0)',
      icon: 'mage:file-3',
      submenu: [
        { id: 'Categories', title: 'Categories', link: PAGES.category },
        { id: 'Subcategories', title: 'Sub-categories', link: PAGES.subcategory },
      ],
    },
    {
      id: 'ConciergeRequests',
      title: 'Concierge Requests',
      link: PAGES.conciergeRequests,
      icon: 'ph:heart-bold',
    },
    {
      id: 'Dates',
      title: 'Dates',
      link: PAGES.dates,
      icon: 'mingcute:calendar-2-line',
    },
    {
      id: 'SubscriptionPackages',
      title: 'Subscription Packages',
      link: PAGES.subscriptionPackages,
      icon: 'lucide:package',
    },
    {
      id: 'ContactQueries',
      title: 'Contact Queries',
      link:PAGES.contactQueries,
      icon: 'mage:message-question-mark-round',
    },
    {
      id: 'Reports',
      title: 'Reports',
      link: PAGES.reports,
      icon: 'tabler:file-report',
    },
    {
      id: 'Pages',
      title: 'All Pages',
      link: PAGES.allPages,
      icon: 'bx:file',
    },
    {
      id: 'ProfileSettings',
      title: 'Profile Settings',
      link: PAGES.profileSettings,
      icon: 'solar:settings-outline',
    }
  ];

  useEffect(() => {
    const currentPage = pages.find(page => 
      (page.submenu && isSubmenuItemActive(page.submenu))
    );
    if (currentPage && currentPage.submenu && isSubmenuItemActive(currentPage.submenu)) {
      setOpenSubmenu(currentPage.id);
    } else {
      setOpenSubmenu(null);
    }
  }, [location.pathname]);

  return (
    <>
      <button onClick={toggleSidebar} className="btnopen" style={{ display: 'none' }}>
        <Icon icon="solar:hamburger-menu-broken" />
      </button>
      <button className={sidebarOpen ? "btnclose sidebarbg-open" : "btnclose sidebarbg-closed"} style={{ display: 'none' }} onClick={toggleSidebar}></button>

      <div className={sidebarOpen ? "sidebar sidebar-open" : "sidebar sidebar-closed"}>
        <div className='sidebarlogo_div'>
          <Link to="/Dashboard"><img src={logodark} alt="Logo" /></Link>
        </div>
        <div className="sidebarouter">
          {pages.map(page => (
            <div key={page.id} className="navitem-container">
              <NavLink
                to={page.link}
                className={`navitem ${(openSubmenu === page.id || (page.submenu && isSubmenuItemActive(page.submenu))) ? 'active' : ''}`}
                onClick={page.submenu ? (e) => {
                  e.preventDefault();
                  handleSubmenuClick(page.id);
                } : handleNavLinkClick}
              >
                <div className="flex-grow-1">
                  <Icon icon={page.icon} />
                  {page.title}
                </div>
                {page.submenu && <Icon icon={openSubmenu === page.id ? "mdi:chevron-up" : "mdi:chevron-down"} className={`submenu-icon me-0 ${openSubmenu === page.id ? 'open' : ''}`} />}
                
              </NavLink>
              {page.submenu && openSubmenu === page.id && (
                <div className="submenu">
                  {page.submenu.map(sub => (
                    <NavLink
                      key={sub.id}
                      to={sub.link}
                      className="submenu-item"
                      onClick={handleNavLinkClick}
                    >
                      {sub.title}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
