import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PAGES from "../../constants/pages";
export default function Datedetail() {
  const location = useLocation();
  const data = location?.state?.data || {};

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Date Details</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to={PAGES.dates}
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Row className="g-3 mb-4">
            <Col md="6">
              <div className="dateinvoice_box">
                <h3>By</h3>
                <h4 className="dateinvoice_name">{data?.sender_id?.name}</h4>
                <h4 className="dateinvoice_email">{data?.sender_id?.email}</h4>
              </div>
            </Col>
            <Col md="6">
              <div className="dateinvoice_box">
                <h3>With</h3>
                <h4 className="dateinvoice_name">{data?.receiver_id?.name}</h4>
                <h4 className="dateinvoice_email">
                  {data?.receiver_id?.email}
                </h4>
              </div>
            </Col>
            <Col md="12">
              <div className="dateinvoice_box">
                <h4 className="dateinvoice_dtl mb-2">
                  {data?.destination?.location}
                </h4>
                <h4 className="dateinvoice_dtl mb-2">
                  Start Date: {new Date(data?.dates[0]).toDateString()}
                </h4>
                <h4 className="dateinvoice_dtl">
                  End Date:{" "}
                  {new Date(
                    data?.dates[data?.dates?.length - 1]
                  ).toDateString()}
                </h4>
                <h4 style={{ marginTop: ".5rem" }} className="dateinvoice_dtl">
                  Adventure:{" "}
                  {Array.isArray(data?.adventures) ? (
                    data?.adventures?.map((adventure, index) => (
                      <span
                        key={index}
                        className="badge adventure_badge bg-secondary"
                        style={{ margin: "2px" }}
                      >
                        {adventure?.name}
                      </span>
                    ))
                  ) : (
                    <span>No adventures</span>
                  )}
                </h4>
              </div>
            </Col>
          </Row>
          <Row className="g-3 mb-4">
            {data?.address?.map((item, index) => {
              return (
                <Col key={index} md="4">
                  <div className="datedtl_box">
                    <h4>{item?.title}</h4>
                    <h6 style={{ fontSize: "13px" }}>{item?.location}</h6>
                    <p className="date_text">{`Date: ${item?.date} | ${item?.time}`}</p>
                    <p className="price_text">{`Amount: $${item?.amount}`}</p>
                    <p className="date_desc">{item?.description}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div>
            <h5 className="dtlmain_head">Receipt</h5>
            <Table responsive>
              <tbody>
                {data?.address?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <b>{item?.title}</b>
                      </td>
                      <td>${item.amount}</td>
                    </tr>
                  );
                })}
                {data.tax !== "0" && (
                  <tr>
                    <td>
                      <b>Tax</b>
                    </td>
                    <td>${data.tax}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Total</b>
                  </td>
                  <td>${data.total}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
