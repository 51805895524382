import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutSuccess } from "../authSlice";
import config from "../../constants/config";

const baseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  const { store } = await import("../store");
  const authToken = localStorage.getItem("authToken");
  const headers = {
    ...(!!authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    ...baseQuery.headers,
  };

  let result = await baseQuery({ ...args, headers }, api, extraOptions);
  if (result.error && result.error.originalStatus === 401) {
    // console.log("🚀 ~ baseQueryWithInterceptor ~ status:", result.error);
    store.dispatch(logoutSuccess());
  }
  return result;
};

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
});