import React from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  usePlansListQuery,
  useUpdatePlansMutation,
} from "../../store/services/home/home";
import { showToast } from "../../utils/toast";
import NoResult from "./NoResult";
import { ToastContainer } from "react-toastify";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function SubscriptionPackages() {
  const { data: plansList, status, refetch } = usePlansListQuery();
  const [updatePlanMutate, { isLoading }] = useUpdatePlansMutation();
  const isLoaded = status === "pending" ? false : true;
  const [show2, setShow2] = React.useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [Title, setTitle] = React.useState("");
  const [Amount, setAmount] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [editorData, setEditorData] = React.useState("");
  const [planId, setPlanId] = React.useState("");

  const validationSchema = Yup.object().shape({
    Title: Yup.string().required("Title is required"),
    Amount: Yup.number()
    .typeError('Amount must be a number')
    .positive('Amount must be a positive number')
    .required('Amount is required'),
    description: Yup.string().required("Description is required"),
  });

  const columns = [
    {
      name: "Title",
      selector: (row) => row.Title,
      sortable: false,
      cell: (row) => row?.plan_name,
    },
    {
      name: "Amount",
      selector: (row) => row?.plan_price,
      sortable: false,
      cell: (row) => `$${row?.plan_price}`,
    },
    {
      name: "Features",
      selector: (row) => row.Features,
      sortable: false,
      cell: (row) => {
        let modifiedString = row?.plan_description.replace(/,/g, "<br/> ");
        return (
          <div
            className="truncate"
            title={modifiedString.replace(/<br\/>/g, "\n")}
            dangerouslySetInnerHTML={{ __html: modifiedString }}
          />
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnedit" onClick={() => handleOpenEditor(row)}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
        </>
      ),
    },
  ];

  const filteredData = plansList?.body?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !==
      -1
  );

  const handleOpenEditor = (row) => {
    let des = `<p>${row?.plan_description.split(",").join("<br>")}</p>`;
    setPlanId(row?._id);
    setEditorData(des);
    setDescription(row?.plan_description);
    setAmount(row?.plan_price);
    setTitle(row?.plan_name);
    handleShow2();
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Card>
        <Card.Header className="py-4">
          <h5>Subscription Packages</h5>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          {filteredData?.length ? (
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              responsive
              className="custom-table"
            />
          ) : (
            <div>
              {!isLoaded ? (
                <div className="loading-state-changed">
                  <div className="loading"></div>
                </div>
              ) : (
                <NoResult />
              )}
            </div>
          )}
        </Card.Body>
      </Card>
      <Modal size="lg" show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Subscription Package</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              Title: Title || "",
              Amount: Amount || "",
              description: description || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const body = {
                id: planId,
                plan_name: values.Title,
                plan_price: values.Amount,
                plan_description: values.description,
              };

              updatePlanMutate(body)
                .unwrap()
                .then((res) => {
                  if (res?.success) {
                    showToast("success", res?.message);
                    refetch();
                    handleClose2();
                  } else {
                    showToast("error", res?.message);
                  }
                })
                .catch((error) => {
                  showToast("error", "Something went wrong, please try again");
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <FormikForm>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Title</Form.Label>
                  <Field
                    name="Title"
                    type="text"
                    placeholder="Enter Title"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="Title"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Amount</Form.Label>
                  <Field
                    name="Amount"
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="Amount"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Description</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    onChange={(event, editor) => {
                      let data = editor.getData();
                      setFieldValue(
                        "description",
                        data
                          .replace(/<br\s*\/?>/gi, ",")
                          .replace(/&nbsp;/g, " ")
                          .replace(/<[^>]+>/g, "")
                          .trim()
                          .replace(/\s*,\s*/g, ", ")
                      );
                      setEditorData(data);
                    }}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Button type="submit" className="btn btn-primary px-4 w-100">
                  {isSubmitting ? "Updating..." : "Update"}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
