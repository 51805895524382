import React, { useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  useReportDeleteQuery,
  useReportListQuery,
} from "../../store/services/home/home";
import NoResult from "./NoResult";
import { debounce } from "../../utils/common";
export default function Reports() {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const {
    data: reportList,
    status,
    refetch,
  } = useReportListQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search:search
  });
  const [reportId, setReportId] = React.useState("");
  const [deleteReport, setDeleteReport] = React.useState(false);
  const { isLoading } = useReportDeleteQuery(
    { id: reportId },
    { skip: !deleteReport }
  );
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isLoaded = status === "pending" ? false : true;

  React.useEffect(() => {
    if (reportList?.body?.totalCount) {
      setTotalRows(reportList?.body?.totalCount); 
    }
  }, [reportList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: "Reported By",
      minWidth: "180px",
      selector: (row) => row.Reportedbyname,
      sortable: false,
      cell: (row) => `${row?.Reportedbyname === undefined ? "" : row?.Reportedbyname} \n ${row?.user_id?.email === undefined ? "" : row?.user_id?.email}`,
    },
    {
      name: "Reported To",
      minWidth: "180px",
      selector: (row) => row.Reportedtoname,
      sortable: false,
      cell: (row) => `${row?.Reportedtoname === undefined ? "" : row?.Reportedtoname} \n ${row?.reported_user_id?.email === undefined ? "" : row?.reported_user_id?.email}`,
    },
    {
      name: "Reason",
      minWidth: "350px",
      selector: (row) => row.Reason,
      sortable: false,
      cell: (row) => row?.reason,
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: false,
      cell: (row) => new Date(row?.createdAt).toDateString(),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link
            className="btndelete"
            onClick={() => {
              setReportId(row?._id);
              handleShow();
            }}
          >
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];

  //Handle delete report
  const handleDeleteReport = () => {
    setDeleteReport(true);
    setTimeout(() => {
      refetch();
    }, 200);
    handleClose();
  };

  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-4">
              <h5>Reports</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) =>{ debouncedSearchTextValue(e);      setSearchText(e.target.value);}}
                />
              </div>
              {reportList?.body?.report?.length ? (
                <DataTable
                  columns={columns}
                  data={reportList?.body?.report}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  responsive
                  className="custom-table"
                />
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button
            onClick={handleClose}
            className="btn btn-primary-outline px-4 me-3"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary px-4 min_width110"
            onClick={handleDeleteReport}
          >
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
