import React from "react";
import { Modal, Card, Form, Button, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  useCategoriesListQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from "../../store/services/home/home";
import config from "../../constants/config";
import { debounce, uploadFiles } from "../../utils/common";
import { showToast } from "../../utils/toast";
import NoResult from "./NoResult";
import { ToastContainer } from "react-toastify";
import instance from "../../utils/instance";
export default function Category() {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);

  const [updateCategoryMutate, { isLoading: isCategoryUpdating }] =
    useUpdateCategoryMutation();
  const [addCategoryMutate, { isLoading: isCategoryAdding }] =
    useCreateCategoryMutation();

  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [show3, setShow3] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState("");
  const [categoryId, setCategoryId] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [categoryIcon, setCategoryIcon] = React.useState(null);
  const [categoryImage, setCategoryImage] = React.useState(null);
  const [categoryIconURL, setCategoryIconURL] = React.useState(null);
  const [categoryImageURL, setCategoryImageURL] = React.useState(null);
  const [isIconUploading, setIsIconUploading] = React.useState(false);
  const [isImageUploading, setIsImageUploading] = React.useState(false);
  const [isStatusUpdating, setIsStatusUpdating] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const {
    data: categories,
    status,
    refetch,
  } = useCategoriesListQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search:search
  });

  const isLoaded = status === "pending" ? false : true;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  React.useEffect(() => {
    if (categories?.body?.totalCount) {
      setTotalRows(categories?.body?.totalCount); // Assuming total is available in response
    }
  }, [categories]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.name,
       sortable: false,
      cell: (row) => (
        <>
          <img
            alt="Category Image"
            className="table_img rounded-circle img-thumbnail me-2 cate_image_change"
            src={`${config.IMAGES_URL}${row.icon}`}
          />
          {row.name}
        </>
      ),
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt).toDateString(),
       sortable: false,
    },
    {
      name: "Status",
      selector: (row) => (row.is_active === "1" ? "Active" : "Inactive"),
       sortable: false,
      cell: (row) => (
        <span
          className={
            row.is_active === "1" ? "Active" + " badge" : "Inactive" + " badge"
          }
        >
          {row.is_active === "1" ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link
            className="btnedit"
            onClick={() => {
              getPreviousDetails(row);
              handleShow2();
            }}
          >
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link
            className="btndelete"
            onClick={() => {
              handleShow3();
              setCategoryId(row?._id);
            }}
          >
            <Icon icon="tabler:trash"></Icon>
          </Link>
          <Link
            className="category-status"
            onClick={() => {
              setIsStatusUpdating(true);
              handleUpdateStatus(row?._id, row.is_active);
              setCategoryId(row?._id);
            }}
          >
            <p>
              {isCategoryUpdating && isStatusUpdating && row?._id === categoryId
                ? "Updating..."
                : "Change Status"}
            </p>
          </Link>
        </>
      ),
    },
  ];


  //set previous category data
  const getPreviousDetails = (data) => {
    setCategoryId(data?._id);
    setCategoryName(data?.name);
    setCategoryIcon(`${config.IMAGES_URL}${data.icon}`);
    setCategoryImage(`${config.IMAGES_URL}${data.image_url}`);
    setCategoryImageURL(data.image_url);
    setCategoryIconURL(data.icon);
  };

  //handle upload Image
  const handleUploadImage = async (e, type) => {
    const file = e.target.files[0];
    try {
      if (type === 1) {
        setIsIconUploading(true);
      } else {
        setIsImageUploading(true);
      }

      const result = await uploadFiles(file);
      // console.log(result);
      if (result?.success) {
        if (type === 1) {
          setCategoryIcon(URL.createObjectURL(file));
          setCategoryIconURL(result?.body[0]);
        } else {
          setCategoryImage(URL.createObjectURL(file));
          setCategoryImageURL(result?.body[0]);
        }
      }
    } catch (error) {
      console.error("File upload failed:", error);
    } finally {
      if (type === 1) {
        setIsIconUploading(false);
      } else {
        setIsImageUploading(false);
      }
    }
  };

  // handle Category update
  const handleCategoryUpdate = async () => {
    const body = {
      id: categoryId,
      name: categoryName,
      icon: categoryIconURL,
      image_url: categoryImageURL,
    };
    if (!categoryName || !categoryIconURL || !categoryImageURL) {
      showToast("warning", "All fields are required!");
      return;
    } else {
      try {
        const res = await updateCategoryMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          handleClose2();
          refetch();
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        console.log("Error updating category", error);
      }
    }
  };

  // handle Category update
  const handleAddCategory = async () => {
    const body = {
      name: categoryName,
      icon: categoryIconURL,
      image_url: categoryImageURL,
    };

    if (!categoryName || !categoryIconURL || !categoryImageURL) {
      showToast("warning", "All fields are required!");
      return;
    } else {
      try {
        const res = await addCategoryMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          handleClose();
          refetch();
          setCategoryId("");
          setCategoryName("");
          setCategoryIcon("");
          setCategoryImage("");
          setCategoryImageURL("");
          setCategoryIconURL("");
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        console.log("Error updating category", error);
      }
    }
  };

  const handleUpdateStatus = async (id, status) => {
    const body = {
      id: id,
      is_active: status === "1" ? "0" : "1",
    };
    try {
      const res = await updateCategoryMutate(body).unwrap();
      if (res?.success) {
        setIsStatusUpdating(false);
        showToast("success", res?.message);
        handleClose2();
        refetch();
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      console.log("Error updating category", error);
    } finally {
      setIsStatusUpdating(false);
    }
  };

  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );

  //Handle delete category
  const handleDeleteCategory = async () => {
    try {
      setLoading(true);
      const { data } = await instance.get(
        "/admin/removed-category?id=" + categoryId
      );
      if (data?.success) {
        refetch();
        setLoading(false);
        handleClose3();
        showToast("success", data?.message);
      }
    } catch (error) {
      console.log("Error deleting category", error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Categories</h5>
          <Button
            className="btn btn-primary px-4 min_width140"
            onClick={handleShow}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) =>{ debouncedSearchTextValue(e);      setSearchText(e.target.value);}}
            />
          </div>
          {categories?.body?.categories?.length ? (
            <DataTable
              columns={columns}
              data={categories?.body?.categories}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              responsive
              className="custom-table"
            />
          ) : (
            <div>
              {!isLoaded ? (
                <div className="loading-state-changed">
                  <div className="loading"></div>
                </div>
              ) : (
                <NoResult />
              )}
            </div>
          )}
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Icon</Form.Label>
              <Form.Control
                id="categoryIcon"
                name="categoryIcon"
                className="displaynone"
                type="file"
                onChange={(e) => handleUploadImage(e, 1)}
              />
              <Form.Label className="fileupload" htmlFor="categoryIcon">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Upload Icon</h3>
                <div className="formimg">
                  {isIconUploading ? (
                    <div className="spinner-view">
                      <Spinner animation="border" role="status" />
                    </div>
                  ) : (
                    <>
                      {categoryIconURL ? (
                        <img
                          alt="Close"
                          src={`${config.IMAGES_URL}/${categoryIconURL}`}
                          className="cate-icon"
                        />
                      ) : null}
                    </>
                  )}
                  {!isIconUploading && categoryIconURL && (
                    <Icon
                      onClick={() => {
                        setCategoryIcon(null);
                        setCategoryIconURL(null);
                      }}
                      className="closebtn"
                      icon="material-symbols:close"
                    ></Icon>
                  )}
                </div>
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                id="categoryimg"
                name="categoryimg"
                className="displaynone"
                type="file"
                onChange={(e) => handleUploadImage(e, 2)}
              />
              <Form.Label className="fileupload" htmlFor="categoryimg">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Upload Image</h3>
                <div className="formimg">
                  {isImageUploading ? (
                    <div className="spinner-view">
                      <Spinner animation="border" role="status" />
                    </div>
                  ) : (
                    categoryImageURL && (
                      <img
                        alt="Close"
                        src={`${config.IMAGES_URL}/${categoryImageURL}`}
                        className="cate-icon"
                      />
                    )
                  )}
                  {!isImageUploading && categoryImageURL && (
                    <Icon
                      onClick={() => {
                        setCategoryImage(null);
                        setCategoryImageURL(null);
                      }}
                      className="closebtn"
                      icon="material-symbols:close"
                    ></Icon>
                  )}
                </div>
              </Form.Label>
            </Form.Group>
          </Form>
          <Button
            onClick={handleAddCategory}
            className="btn btn-primary px-4 w-100"
            disabled={isCategoryAdding}
          >
            {isCategoryAdding ? "Adding..." : "Add"}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Icon</Form.Label>
              <Form.Control
                id="categoryIcon"
                name="categoryIcon"
                className="displaynone"
                type="file"
                onChange={(e) => handleUploadImage(e, 1)}
              />
              <Form.Label className="fileupload" htmlFor="categoryIcon">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Upload Icon</h3>
              </Form.Label>
              <div className="formimg">
                {isIconUploading ? (
                  <div className="spinner-view">
                    <Spinner animation="border" role="status" />
                  </div>
                ) : (
                  categoryIcon && (
                    <img alt="Close" src={categoryIcon} className="cate-icon" />
                  )
                )}
                {!isIconUploading && categoryIconURL && (
                  <Icon
                    onClick={() => {
                      setCategoryIcon(null);
                      setCategoryIconURL(null);
                    }}
                    className="closebtn"
                    icon="material-symbols:close"
                  ></Icon>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                id="categoryimg"
                name="categoryimg"
                className="displaynone"
                type="file"
                onChange={(e) => handleUploadImage(e, 2)}
              />
              <Form.Label className="fileupload" htmlFor="categoryimg">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Upload Image</h3>
              </Form.Label>
              <div className="formimg">
                {isImageUploading ? (
                  <div className="spinner-view">
                    <Spinner animation="border" role="status" />
                  </div>
                ) : (
                  categoryImage && (
                    <img
                      alt="Close"
                      src={categoryImage}
                      className="cate-icon"
                    />
                  )
                )}
                {!isImageUploading && categoryImageURL && (
                  <Icon
                    onClick={() => {
                      setCategoryImage(null);
                      setCategoryImageURL(null);
                    }}
                    className="closebtn"
                    icon="material-symbols:close"
                  ></Icon>
                )}
              </div>
            </Form.Group>
          </Form>
          <Button
            onClick={handleCategoryUpdate}
            disabled={isCategoryUpdating}
            className="btn btn-primary px-4 w-100"
          >
            {isCategoryUpdating ? "Updating..." : "Update"}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        className="modal_Delete"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>
              {loading
                ? "Loading..."
                : "You will not be able to recover the deleted record!"}
            </p>
          </div>
          <Button
            onClick={handleClose3}
            className="btn btn-primary-outline px-4 me-3"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary px-4 min_width110"
            onClick={handleDeleteCategory}
          >
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
