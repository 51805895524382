import React, { useState } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import DummyUser from "../assets/img/default.jpg";
import PAGES from "../../constants/pages";
import { useDashboardInfoQuery, useDeleteUserMutation, useUpdateUserMutation } from "../../store/services/home/home";
import config from "../../constants/config";
import { showToast } from "../../utils/toast";
import { ToastContainer } from "react-toastify";

export default function Dashboard() {
  const [searchText, setSearchText] = useState("");
  const {data:dashboardInfo,isLoading,refetch} = useDashboardInfoQuery();
  const [updateUserMutate, { isLoading: userUpdating }] =
  useUpdateUserMutation();
  const [userId, setUserId] = React.useState("");
  const [show, setShow] = useState(false);
  const [deleteUser,{isLoading:deleteLoading}] = useDeleteUserMutation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const columns = [
    {
      name: "Name",
      // minWidth: '190px',
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            src={
              row?.profile_url
                ? `${config.IMAGES_URL}${row?.profile_url}`
                : DummyUser
            }
            alt="Image"
          />
          {row.name}
        </>
      ),
    },
    {
      name: "Email/Phone",
      selector: (row) => row.email,
       sortable: false,
      cell: (row) => `${row.email === undefined ? "" : row.email} \n${row.phone === undefined ? "" : row.phone}`,
    },
    {
      name: "DOB",
      selector: (row) => row.dob,
       sortable: false,
      cell: (row) => row.dob,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
       sortable: false,
      cell: (row) => row.gender,
    },
    {
      name: "Status",
      selector: (row) => (row.is_active === "1" ? "Active" : "Inactive"),
       sortable: false,
      cell: (row) => (
        <span
          className={row.is_active === "1" ? "Active" + " badge" : "Inactive" + " badge"}
        >
          {row.is_active === "1" ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      name: "Actions",
       minWidth: '300px',
      cell: (row) => (
        <>
          <Link
            className="btnview"
            to={PAGES.viewUser}
            state={{id:row?._id}}
          >
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" to={PAGES.editUser} state={{ id: row?._id}}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link
            className="btndelete"
            onClick={() => {
              handleShow();
              setUserId(row?._id);
            }}
          >
            <Icon icon="tabler:trash"></Icon>
          </Link>
          <Link
            className="category-status"
            onClick={() => {
              handleUpdateStatus(row?._id, row.is_active);
              setUserId(row?._id);
            }}
          >
            <p>
              {userUpdating && row?._id === userId
                ? "Updating..."
                : "Change Status"}
            </p>
          </Link>
        </>
      ),
    },
  ];


  const handleUpdateStatus = async (id, status) => {
    const body = {
      id: id,
      is_active: status === "1" ? "0" : "1",
    };
    try {
      const res = await updateUserMutate(body).unwrap();
      if (res?.success) {
        showToast("success", res?.message);
        refetch();
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      console.log("Error updating category", error);
    }
  };

  // Filter data based on search text
  const filteredData = dashboardInfo?.body?.usersList?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !==
      -1
  );

  const handleDeleteUser = async () => {
    try {
      const body = {
        id: userId,
      }
      const res = await deleteUser(body).unwrap();
      if(res?.success) {
        refetch();
        handleClose();
        showToast("success", res?.message);
      }
    } catch (error) {
      handleClose();
      console.log("Error while deleting user",error)
    }
    
  }

  if (isLoading) {
    return <div className="loading-state">
    <div className="loading"></div>
  </div>;
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Row className="g-3">
        <Col lg={3}>
          <div className="stats">
            <span className="statsicon">
              <Icon icon="tabler:users" />
            </span>
            <div className="stats-cont">
              <h4>Users</h4>
              <p>{dashboardInfo?.body?.userCount}</p>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="stats">
            <span className="statsicon statsgreen">
              <Icon icon="ph:heart-bold" />
            </span>
            <div className="stats-cont">
              <h4>Concierge Requests</h4>
              <p>{dashboardInfo?.body?.conciergesCount}</p>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="stats">
            <span className="statsicon statsgreen">
              <Icon icon="fontisto:date" />
            </span>
            <div className="stats-cont">
              <h4>Dates</h4>
              <p>{dashboardInfo?.body?.datesCount}</p>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="stats">
            <span className="statsicon statsblue">
              {" "}
              <Icon icon="healthicons:money-bag-outline" />
            </span>
            <div className="stats-cont">
              <h4>Revenue</h4>
              <p>${dashboardInfo?.body?.revenueCount?.toFixed(2)}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={12}>
          <Card>
            <Card.Header className="py-3">
              <h5>Recent Users</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>{deleteLoading ? "Loading..." :`You will not be able to recover the deleted record!`}</p>
          </div>
          <Button onClick={handleClose} className="btn btn-primary-outline px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={() => handleDeleteUser()}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
