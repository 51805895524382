import React from "react";
import NoData from "../assets/img/no-record.png"

const NoResult = () => {
  

  return (
    <div>
        <img src={NoData} className="noResultFound" />
        <h3 className="noResultFoundText">No results found.</h3>
    </div>
  );
};

export default NoResult;
