import React from "react";
import { Card, Table} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import PAGES from "../../constants/pages";
import { addOneMonth } from "../../utils/common";
export default function Viewpayment() {
  const location = useLocation();
  const params = location.state || {};
  const data = params?.data || {};
  const id = params?.id ||""
  const userinfo = {
    SrNo: '1',
    Plan: 'Premium',
    Startdate: '20 June 2024',
    Renewdate: '20 July 2024',
    Amount: '$19.99',
    status: 'Completed',
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Payment Details</h5>
          <Link className="btn btn-primary d-inline-flex align-items-center px-4 min_width140" to={PAGES.viewUser} state={{id:id}} >Back</Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Table responsive>
            <tbody>
                  <tr>
                    <td><b>Plan</b></td>
                    <td>{data?.plan_name}</td>
                  </tr>
                  <tr>
                    <td><b>Start Date</b></td>
                    <td>
                            {data?.response?.purchase_date &&
                              data?.response?.purchase_date?.split(" ")[0]}
                          </td>
                  </tr>
                  <tr>
                    <td><b>Renew Date</b></td>
                    <td>{addOneMonth(data?.response?.purchase_date)}</td>
                  </tr>
                  <tr>
                    <td><b>Amount</b></td>
                    <td>${data?.price}</td>
                  </tr>
                  <tr>
                    <td><b>Status</b></td>
                    <td><span className={userinfo.status + ' badge'}  >{userinfo.status}</span></td>
                  </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}