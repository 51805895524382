import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import { useContactQueriesDeleteQuery, useContactQueriesListQuery } from "../../store/services/home/home";
import NoResult from "./NoResult";
import { debounce } from "../../utils/common";
export default function Contactqueries() {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const { data: queriesList, status,refetch } = useContactQueriesListQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search:search
  });
  const [queryId,setQueryId] = React.useState("");
  const [deleteQuery,setDeleteQuery] = React.useState(false);
  const {isLoading} =   useContactQueriesDeleteQuery({id:queryId},{skip:!deleteQuery})
  const [searchText, setSearchText] = React.useState('');
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isLoaded = status === "pending" ? false : true;


  React.useEffect(() => {
    if (queriesList?.body?.totalCount) {
      setTotalRows(queriesList?.body?.totalCount);
    }
  }, [queriesList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: 'Name',
      // minWidth: '180px',
      selector: row => row.name,
      sortable: false,
      cell: (row) => row.name    
    },
    {
      name: 'Email',
      // minWidth: '150px',
      selector: row => row.email,
      sortable: false,
      cell: (row) => row.email
    },
    {
      name: 'Query',
      // minWidth: '350px',
      selector: row => row.Query,
      sortable: false,
      cell: (row) => row.message
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <Link className="btndelete" onClick={() => {setQueryId(row?._id);handleShow();}}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];

  //Handle delete query
  const handleDeleteQuery = () => {
    setDeleteQuery(true);
    setTimeout(() => {
      refetch();
     }, 200);
    handleClose();
  }

  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-4">
              <h5>Contact Queries</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) =>{ debouncedSearchTextValue(e);      setSearchText(e.target.value);}}
                />
              </div>
              {queriesList?.body?.contact?.length ? (
                <DataTable
                  columns={columns}
                  data={queriesList?.body?.contact}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  responsive
                  className="custom-table"
                />
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-primary-outline px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={handleDeleteQuery}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
