import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createMiddleware from "./middleware";
import authSlice from "./authSlice";
import { api } from "./services/api";

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authSlice,
});

const persistConfig = {
  key: "adventureDatingRoot",
  storage: storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: createMiddleware,
  devTools:
    process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test",
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };