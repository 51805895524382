import React, { useState } from "react";
import { Card, Table, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import avatarimg1 from "../assets/img/avatars/1.png";
import { useGetUsersDetailsQuery } from "../../store/services/home/home";
import config from "../../constants/config";
import PAGES from "../../constants/pages";
import { addOneMonth, formatDate } from "../../utils/common";
import NoResult from "./NoResult";
export default function Viewuser() {
  const location = useLocation();
  const id = location.state || {};
  const [key, setKey] = useState("GeneralInfo"); 

  let type =
    (key === "GeneralInfo" && 1) ||
    (key === "Payments" && 2) ||
    (key === "Dates" && 3);
  const { data, isLoading, status ,refetch} = useGetUsersDetailsQuery(
    { id: id, type },
    { skip: !id?.id }
  );
  const [availability, setAvailability] = React.useState(null);
  const isLoaded = status === "pending" ? false : true;


  React.useEffect(() => {
    refetch();
  },[location])

  //Set user availability
  React.useEffect(() => {
    const allDates = data?.body?.userdata?.availability && data?.body?.userdata?.availability?.split(",");
    const allDays =
      allDates &&
      allDates?.map((day) => {
        switch (day) {
          case "M":
            return "Monday";
          case "Tu":
            return "Tuesday";
          case "W":
            return "Wednesday";
          case "Th":
            return "Thursday";
          case "F":
            return "Friday";
          case "Sa":
            return "Saturday";
          case "Su":
            return "Sunday";
          default:
            return "No Availability Found";
        }
      });
    const sortedDays = allDays?.join(", ");
    sortedDays && setAvailability(sortedDays);
  }, [data]);

  //Show spinner if data not loaded
  if (isLoading) {
    return (
      <div className="loading-state">
        <div className="loading"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>User Details</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to={id?.screen === "users" ? PAGES.users : PAGES.dashboard}
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Tabs
            defaultActiveKey="GeneralInfo"
            id="uncontrolled-tab-example"
            className="customtabs mb-2"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="GeneralInfo" title="General Info">
              {data?.body && type === 1 ? (
                <Table responsive>
                  <tbody>
                    {data?.body?.userdata?.profile_url && (
                      <tr>
                        <td>
                          <img
                            className="dtlimg img-thumbnail image-thumbnail-changed"
                            src={`${config.IMAGES_URL}/${data?.body?.userdata?.profile_url}`}
                            alt=""
                          />
                        </td>
                        <td></td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{data?.body?.userdata?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email</b>
                      </td>
                      <td>{data?.body?.userdata?.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone Number</b>
                      </td>
                      <td>{data?.body?.userdata?.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>DOB</b>
                      </td>
                      <td>{data?.body?.userdata?.dob}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Gender</b>
                      </td>
                      <td>{data?.body?.userdata?.gender}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Interested In</b>
                      </td>
                      <td>{data?.body?.userdata?.preferences}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{data?.body?.userdata?.location_name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bio</b>
                      </td>
                      <td>{data?.body?.userdata?.about_yourself}</td>
                    </tr>
                    {data?.body?.useradventure &&
                      data?.body?.useradventure?.map((adventure) => {
                        return (
                          <tr>
                            <td>
                              <b>{adventure?.cat_id?.name}</b>
                            </td>
                            <td>{adventure?.sub_cat_id?.name}</td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td>
                        <b>Availability</b>
                      </td>
                      <td>{availability ?? ""}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>
                      {data?.body?.userdata?.is_active == "1" ? (
                        <td>
                          <span className={"Active" + " badge"}>
                            {data?.body?.userdata?.is_active === "1"
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span className={"Inactive" + " badge"}>
                            {data?.body?.userdata?.is_active === "1"
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Tab>
            <Tab eventKey="Payments" title="Payments">
              {data?.body?.length && !isLoading && type === 2 ? (
                <div className="viewtable_box">
                  <Table>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Plan</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.body?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.plan_name}</td>
                          <td>
                            {item?.response?.purchase_date &&
                              item?.response?.purchase_date?.split(" ")[0]}
                          </td>
                          <td>
                            {item?.response?.expires_date &&
                              item?.response?.expires_date?.split(" ")[0]}
                          </td>
                          <td>${item?.price}</td>
                          <td>
                            <span className={item.status + " badge"}>
                              {item?.status}
                            </span>
                          </td>
                          <td>
                            <Link
                              className="btnview"
                              to={PAGES.viewPayment}
                              state={{ id: id?.id, data: item }}
                            >
                              <Icon icon="tabler:eye"></Icon>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Tab>
            <Tab eventKey="Dates" title="Dates">
              {data?.body?.length && !isLoading && type === 3 ? (
                <div className="viewtable_box">
                  <Table>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Partner</th>
                        <th>Location</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.body?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {id?._id === item?.sender_id?._id
                              ? item?.sender_id?.name
                              : item?.receiver_id?.name}
                            <br />
                            {id?._id === item?.sender_id?._id
                              ? item?.sender_id?.email
                              : item?.receiver_id?.email}
                          </td>
                          <td>{item?.destination?.location}</td>
                          <td>{item?.dates?.length ?  new Date(item?.dates).toDateString() : ""}</td>
                          <td>{item?.dates?.length ? new Date(item?.dates).toDateString() : ""}</td>
                          <td>${item?.total}</td>
                          <td>
                            <Link
                              className="btnview"
                              to={PAGES.userDateDetail}
                              state={{ id: id?.id, data: item }}
                            >
                              <Icon icon="tabler:eye"></Icon>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
