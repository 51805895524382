import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from "../../store/services/home/home";
import config from "../../constants/config";
import Avatar from "../assets/img/default.jpg";
import PAGES from "../../constants/pages";
import { showToast } from "../../utils/toast";
import NoResult from "./NoResult";
import { ToastContainer } from "react-toastify";
import { debounce } from "../../utils/common";

export default function Users() {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);
  const [updateUserMutate, { isLoading: userUpdating }] =
  useUpdateUserMutation();
  const [userId, setUserId] = React.useState("");
  const [search, setSearch] = React.useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const { data: usersData, status,refetch } = useGetUsersQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search: search
  });

  const [deleteUser,{isLoading:deleteLoading}] = useDeleteUserMutation();

  const isLoaded = status !== "pending";

  useEffect(() => {
    if (usersData?.body?.totalCount) {
      setTotalRows(usersData?.body?.totalCount); 
    }
  }, [usersData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
       sortable: false,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            src={row?.profile_url ? `${config.IMAGES_URL}${row.profile_url}` : Avatar}
            alt="Image"
          />
          {row.name}
        </>
      ),
    },
    {
      name: "Email/Phone",
      selector: (row) => row.email,
       sortable: false,
      cell: (row) => `${row.email === undefined ? "" : row.email} \n${row.phone === undefined ? "" : row.phone}`,
    },
    {
      name: "DOB",
      selector: (row) => row.dob,
       sortable: false,
      cell: (row) => row.dob,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
       sortable: false,
      cell: (row) => row.gender,
    },
    {
      name: "Status",
      selector: (row) => (row.is_active === "1" ? "Active" : "Inactive"),
       sortable: false,
      cell: (row) => (
        <span className={row.is_active === "1" ? "Active badge" : "Inactive badge"}>
          {row.is_active === "1" ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      name: "Actions",
      minWidth: '300px',
      cell: (row) => (
        <>
          <Link className="btnview" to={PAGES.viewUser} state={{ id: row?._id, screen: 'users' }}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" to={PAGES.editUser} state={{ id: row?._id}}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link
            className="btndelete"
            onClick={() => {
              handleShow();
              setUserId(row?._id);
            }}
          >
            <Icon icon="tabler:trash"></Icon>
          </Link>
          <Link
            className="category-status"
            onClick={() => {
              handleUpdateStatus(row?._id, row.is_active);
              setUserId(row?._id);
            }}
          >
            <p>
              {userUpdating && row?._id === userId
                ? "Updating..."
                : "Change Status"}
            </p>
          </Link>
        </>
      ),
    },
  ];
  
  const handleUpdateStatus = async (id, status) => {
    const body = {
      id: id,
      is_active: status === "1" ? "0" : "1",
    };
    try {
      const res = await updateUserMutate(body).unwrap();
      if (res?.success) {
        showToast("success", res?.message);
        refetch();
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      console.log("Error updating category", error);
    }
  };


  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );

  const handleDeleteUser = async () => {
    try {
      const body = {
        id: userId,
      }
      const res = await deleteUser(body).unwrap();
      if(res?.success) {
        refetch();
        handleClose();
        showToast("success", res?.message);
      }
    } catch (error) {
      handleClose();
      console.log("Error while deleting user",error)
    }
    
  }


  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Users</h5>
              <Button className="btn btn-primary px-4 min_width140" as={Link} to={PAGES.addUser}>Add</Button>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) =>{ debouncedSearchTextValue(e);      setSearchText(e.target.value);}}
                />
              </div>
              {usersData?.body?.users?.length ? (
                <DataTable
                  columns={columns}
                  data={usersData?.body?.users}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  responsive
                  className="custom-table"
                />
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>{deleteLoading ? "Loading..." :`You will not be able to recover the deleted record!`}</p>
          </div>
          <Button onClick={handleClose} className="btn btn-primary-outline px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={() => handleDeleteUser()}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
