import React, { useEffect } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLoginMutation } from "../../store/services/auth/auth";
import { showToast } from '../../utils/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/authSlice";
import PAGES from "../../constants/pages";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginMutate, { isLoading }] = useLoginMutation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
        password: values.password,
      };
      try {
        const res = await loginMutate(body).unwrap();
        if (res?.success) {
          // console.log("res from login API", res);
          dispatch(loginSuccess(res?.body));
          showToast('success', 'Login successful!');
          navigate(PAGES.dashboard, { replace: true });
        } else {
          // console.log("Error when admin logging");
          showToast('error', res?.message);
        }
      } catch (error) {
        // console.log("Error when admin logging", error);
        showToast('error', 'Login failed. Please try again.');
      }
    },
  });

  useEffect(() => {
    document.body.classList.add('loginbody');
    return () => {
      document.body.classList.remove('loginbody');
    };
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Welcome To<br /> Adventure Dating</h2>
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && formik.touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.password && formik.touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 form-group forgotpassword text-end">
                <Link to={PAGES.forgotPassword}>Forgot Password?</Link>
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid || !formik.dirty}>
                {isLoading ? 'Loading...' : 'Login'}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
