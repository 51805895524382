import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Button, Modal, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PAGES from "../../constants/pages";
import {
  useConciergeApproveMutation,
  useConciergeRejectMutation,
  useDateRequestListQuery,
} from "../../store/services/home/home";
import { Formik, Form as FormikForm, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { showToast } from "../../utils/toast";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { format } from "date-fns";
import { useGetAdminDetailsQuery } from "../../store/services/auth/auth";

export default function Viewrequests() {
  const location = useLocation();
  const { refetch } = useDateRequestListQuery();
  const navigate = useNavigate();
  const data = location?.state?.data || {};
  const [conciergeApproveMutate, { isLoading: conciergeApproving }] =
    useConciergeApproveMutation();
  const [conciergeRejectMutate, { isLoading: conciergeRejecting }] =
    useConciergeRejectMutation();
  const { data: adminData } = useGetAdminDetailsQuery();
  const [Message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [subtotal, setSubtotal] = useState(0); // State to hold subtotal amount
  const [taxAmount, setTaxAmount] = useState(0); // State to hold tax amount
  const [totalAmount, setTotalAmount] = useState(0); // State to hold total amount
  const taxPercentage = adminData?.body?.admin_tax;
  const taxInPercentage = adminData?.body?.admin_tax / 100;

  const today = format(new Date(), "yyyy-MM-dd");

  const [formFields, setFormFields] = useState([
    {
      title: "",
      location: "",
      latitude: "",
      longitude: "",
      date: "",
      time: "",
      amount: "",
      description: "",
    },
  ]);

  const handleAddFields = () => {
    setFormFields([
      ...formFields,
      {
        title: "",
        location: "",
        latitude: "",
        longitude: "",
        date: "",
        time: "",
        amount: "",
        description: "",
      },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
  };

  // // Function to calculate total amount
  const calculateTotal = (values) => {
    let subtotal = 0;
    values &&
      values?.forEach((field) => {
        subtotal += parseFloat(field) || 0;
      });
    const tax = taxPercentage === "0" ? 0 : subtotal * taxInPercentage; // Calculate tax (8%)
    const total = taxPercentage === "0" ? subtotal : subtotal + tax; // Calculate total amount

    setSubtotal(subtotal.toFixed(2));
    setTotalAmount(total.toFixed(2));
    setTaxAmount(tax.toFixed(2));

    return subtotal.toFixed(2); // Return subtotal for any other use
  };

  const handleApproveSubmit = async (values) => {
    try {
      const dateArr = formFields.map((field, index) => ({
        title: values.title[index],
        location: values.location[index],
        latitude: values.latitude[index],
        longitude: values.longitude[index],
        date: values.date[index],
        time: values.time[index],
        amount: values.amount[index],
        description: values.description[index],
      }));

      // Filter out empty objects
      const filteredDateArr = dateArr.filter(
        (item) =>
          item.title &&
          item.location &&
          item.latitude !== undefined &&
          item.longitude !== undefined &&
          item.date &&
          item.time &&
          item.amount !== undefined &&
          item.description
      );

      // Check for specific missing fields and show appropriate messages
      let isValid = true;
      dateArr.forEach((item, index) => {
        // console.log("item.latitud :>> ", item);
        if (
          !item.title ||
          !item.date ||
          !item.time ||
          !item.location ||
          item.amount === undefined ||
          !item.description
        ) {
          isValid = false;
          showToast(
            "warning",
            "All fields are required. Please enter all required fields"
          );
          return;
        }
        if (!item.latitude || !item.longitude) {
          isValid = false;
          showToast("warning", "Please enter a valid location.");
          return;
        }
      });

      if (!isValid) {
        return;
      }

      const body = {
        id: data._id,
        dateArr: filteredDateArr,
        tax: taxPercentage !== "0" ? taxAmount : "0",
        total: totalAmount,
      };

      // console.log("API request", JSON.stringify(body, null, 2));

      conciergeApproveMutate(body).then((res) => {
        if (res?.data?.success) {
          // console.log("Approve success:", res);
          showToast("success", res?.data?.message);
          refetch();
          setTimeout(() => {
            navigate(PAGES.conciergeRequests);
          }, 1000);
          handleClose2();
        } else {
          showToast("error", res?.data?.message);
          console.error("Approve error:", res?.data?.message);
        }
      });
    } catch (error) {
      showToast("error", "Something went wrong. Please try again");
      console.error("Approve error:", error);
    }
  };

  const handleRejectSubmit = async (values) => {
    try {
      const res = await conciergeRejectMutate({
        id: data?._id,
        reason: values.message,
      }).unwrap();

      if (res.success) {
        showToast("success", res?.message);
        refetch();
        setTimeout(() => {
          navigate(PAGES.conciergeRequests);
        }, 1000);
        handleClose();
      } else {
        showToast("error", res?.message);
        // Handle error scenario
        console.error("Reject error:", res.message);
      }
    } catch (error) {
      showToast("error", "Something went wrong. Please try again");
      console.error("Reject error:", error);
    }
  };

  const rejectSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
  });

  const ApproveFormValues = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      calculateTotal(values?.amount);
    }, [values]);

    return null;
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Concierge Request Details</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to={PAGES.conciergeRequests}
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Row className="g-3 mb-4">
            <Col md="6">
              <div className="dateinvoice_box">
                <h3>From</h3>
                <h4 className="dateinvoice_name">{data?.sender_id?.name}</h4>
                <h4 className="dateinvoice_email">{data?.sender_id?.email}</h4>
              </div>
            </Col>
            <Col md="6">
              <div className="dateinvoice_box">
                <h3>With</h3>
                <h4 className="dateinvoice_name">{data?.receiver_id?.name}</h4>
                <h4 className="dateinvoice_email">
                  {data?.receiver_id?.email}
                </h4>
              </div>
            </Col>
          </Row>
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <b>Adventures</b>
                </td>
                <td>
                  {data?.adventures?.map((adventure, index) => (
                    <span key={index}>{adventure?.name}</span>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Location</b>
                </td>
                <td>{data?.destination?.location}</td>
              </tr>
              <tr>
                <td>
                  <b>Start Date</b>
                </td>
                <td>{new Date(data?.dates[0]).toDateString()}</td>
              </tr>
              <tr>
                <td>
                  <b>End Date</b>
                </td>
                <td>
                  {new Date(
                    data?.dates[data?.dates?.length - 1]
                  ).toDateString()}
                </td>
              </tr>
            </tbody>
          </Table>

          <Button
            className="btn btn-primary-outline px-4 mt-3 min_width140 me-2"
            onClick={handleShow}
          >
            Reject
          </Button>
          <Button
            className="btn btn-primary px-4 mt-3 min_width140"
            onClick={handleShow2}
          >
            Approve
          </Button>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="justify-content-center">
          <Modal.Title>
            <h2 className="modalhead">Reason</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ message: "" }}
            validationSchema={rejectSchema}
            onSubmit={(values) => handleRejectSubmit(values)}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Message</Form.Label>
                  <Field
                    as="textarea"
                    rows={5}
                    name="message"
                    placeholder="Enter Here..."
                    className={`form-control ${
                      touched.message && errors.message ? "is-invalid" : ""
                    }`}
                  />
                  {touched.message && errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                </Form.Group>
                <div className="text-center">
                  <Button
                    onClick={handleClose}
                    className="btn btn-primary-outline px-4 me-3"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={conciergeRejecting}
                    type="submit"
                    className="btn btn-primary px-4 min_width110"
                  >
                    {conciergeRejecting ? "Submitting..." : " Submit"}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={show2} onHide={handleClose2} centered>
        <Modal.Header className="justify-content-between">
          <Modal.Title>
            <h2 className="modalhead">Add Date</h2>
          </Modal.Title>
          <Button variant="primary" onClick={handleAddFields}>
            Add More
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title: formFields.map((field) => field.title),
              location: formFields.map((field) => field.location),
              latitude: formFields.map((field) => field.latitude),
              longitude: formFields.map((field) => field.longitude),
              date: formFields.map((field) => field.date),
              time: formFields.map((field) => field.time),
              amount: formFields.map((field) => field.amount),
              description: formFields.map((field) => field.description),
            }}
            onSubmit={(values) => handleApproveSubmit(values)}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              errors,
              touched,
              handleSubmit,
            }) => (
              <FormikForm onSubmit={handleSubmit}>
                <ApproveFormValues />
                {formFields.map((field, index) => (
                  <div key={index}>
                    <Row className="mb-3">
                      <Col md="6">
                        <Form.Group className="form-group mb-3">
                          <Form.Label>{`Title`}</Form.Label>
                          <Field
                            type="text"
                            name={`title[${index}]`}
                            placeholder="Enter Title"
                            className="form-control"
                          />
                          {errors.title &&
                          touched.title &&
                          touched.title[index] ? (
                            <div className="invalid-feedback d-block">
                              {errors.title[index]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Location</Form.Label>
                          <ReactGoogleAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                            onPlaceSelected={async (place) => {
                              // console.log("values :>> ", values);
                              setFieldValue(
                                `location.${index}`,
                                place?.formatted_address
                              );
                              setFieldValue(
                                `latitude.${index}`,
                                place?.geometry?.location?.lat()
                              );
                              setFieldValue(
                                `longitude.${index}`,
                                place?.geometry?.location?.lng()
                              );
                            }}
                            onChange={(e) => {
                              const { value } = e.target;
                              // console.log("onChange value :>> ", value);
                            }}
                            options={{
                              types: ["geocode", "establishment"],
                              fields: [
                                "formatted_address",
                                "geometry.location",
                              ],
                            }}
                            placeholder="Enter a location"
                          />
                          {errors.location &&
                          touched.location &&
                          touched.location[index] ? (
                            <div className="invalid-feedback d-block">
                              {errors.location[index]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Date</Form.Label>
                          <Field
                            type="date"
                            name={`date[${index}]`}
                            placeholder="Enter Date"
                            className="form-control"
                            min={today}
                          />
                          {errors.date &&
                          touched.date &&
                          touched.date[index] ? (
                            <div className="invalid-feedback d-block">
                              {errors.date[index]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Time</Form.Label>
                          <Field
                            type="time"
                            name={`time[${index}]`}
                            placeholder="Enter Time"
                            className="form-control"
                          />
                          {errors.time &&
                          touched.time &&
                          touched.time[index] ? (
                            <div className="invalid-feedback d-block">
                              {errors.time[index]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Amount</Form.Label>
                          <Field
                            type="number"
                            name={`amount[${index}]`}
                            placeholder="Enter Amount"
                            className="form-control"
                          />
                          {errors.amount &&
                          touched.amount &&
                          touched.amount[index] ? (
                            <div className="invalid-feedback d-block">
                              {errors.amount[index]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className="mb-3 form-group">
                          <Form.Label>Description</Form.Label>
                          <Field
                            as="textarea"
                            rows={5}
                            name={`description[${index}]`}
                            placeholder="Enter Here..."
                            className="form-control"
                          />
                          {errors.description &&
                          touched.description &&
                          touched.description[index] ? (
                            <div className="invalid-feedback d-block">
                              {errors.description[index]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    {index > 0 && (
                      <div className="text-end mb-3">
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveFields(index)}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
                <Table responsive>
                  <tbody>
                    <tr>
                      <td>
                        <b>Subtotal</b>
                      </td>
                      <td>${subtotal}</td>
                    </tr>
                    {taxPercentage !== "0" && (
                      <tr>
                        <td>
                          <b>Tax {`(${taxPercentage}%)`}</b>
                        </td>
                        <td>${taxAmount}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>${totalAmount}</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="text-center mt-3">
                  <Button
                    onClick={handleClose2}
                    className="btn btn-primary-outline px-4 me-3"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={conciergeApproving}
                    type="submit"
                    className="btn btn-primary px-4 min_width110"
                  >
                    {conciergeApproving ? "Loading..." : " Send Invoice"}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
