import axios from "axios";
import { store } from "../store/store";
import config from "../constants/config";

// Create an Axios instance with base configuration
const instance = axios.create({
  baseURL: config.API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to inject the auth token dynamically
instance.interceptors.request.use(
  (config) => {
    const authToken = store.getState().auth.authToken;
    console.log('AuthToken from Interceptor:', authToken);
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Response Error:", error);
    return Promise.reject(error);
  }
);

export default instance;
