import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Spinner,
} from "react-bootstrap";
import {
  useGetAdminDetailsQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useUploadFileMutation,
} from "../../store/services/auth/auth";
import { useFormik } from "formik";
import { showToast } from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginSuccess,
  logoutSuccess,
  updateProfileSuccess,
} from "../../store/authSlice";
import PAGES from "../../constants/pages";
import DummyUser from "../assets/img/default.jpg";

import {
  basicInfoSchema,
  passwordSchema,
  taxSchema,
} from "../../constants/validations";
import { uploadFiles } from "../../utils/common";
import config from "../../constants/config";

export default function ProfileSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState("basicinfo");
  const {
    data,
    isLoading: isProfileLoading,
    refetch,
  } = useGetAdminDetailsQuery();
  const [profileUpdateMutate, { isLoading: isProfileUpdating }] =
    useUpdateProfileMutation();
  const [changePasswordMutate, { isLoading: isPasswordUpdating }] =
    useChangePasswordMutation();
  const [
    fileUploadMutation,
    { isLoading: isImageUpdating, isError, data: profileUploadData },
  ] = useUploadFileMutation();
  const [profilePicData, setProfilePicData] = React.useState(null);
  const [profilePic, setProfilePic] = React.useState(null);
  const [isImageUploading, setIsImageUploading] = React.useState(false);
  const profileInfo = data?.body;

  useEffect(() => {
    if (profileInfo?.profile_url) {
      setProfilePic(profileInfo?.profile_url);
    }
  }, [profileInfo]);

  //handle upload Image
  const handleUploadImage = async (e, type) => {
    const file = e.target.files[0];
    try {
      setIsImageUploading(true);
      const result = await uploadFiles(file);
      // console.log(result);
      if (result?.success) {
        setProfilePic(result?.body[0]);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    } finally {
      setIsImageUploading(false);
    }
  };

  //Update basic information
  const basicInfoFormik = useFormik({
    initialValues: {
      name: profileInfo?.name ?? "",
      email: profileInfo?.email ?? "",
      phone: profileInfo?.phone ?? "",
      id: profileInfo?._id ?? "",
      profile_url: profilePic,
    },
    validationSchema: basicInfoSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const res = await profileUpdateMutate(values).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          setTimeout(() => {
            refetch();
            navigate(PAGES.dashboard);
          }, 200);
          // dispatch(updateProfileSuccess(res?.body));
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("error", "Profile update failed. Please try again.");
      }
    },
  });

  //Update admin password
  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      id: profileInfo?._id ?? "",
    },
    validationSchema: passwordSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const body = {
          oldPassword: values.currentPassword,
          newPassword: values.confirmPassword,
        };
        const res = await changePasswordMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          setTimeout(() => {
            dispatch(logoutSuccess());
            navigate(PAGES.login, { replace: true });
          }, 3000);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("error", "Password change failed. Please try again.");
      }
    },
  });

  //Update tax
  const taxFormik = useFormik({
    initialValues: {
      tax: profileInfo?.admin_tax ??  "",
    },
    validationSchema: taxSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const body = {
          id: profileInfo?._id ?? "",
          admin_tax: values.tax,
        };
        const res = await profileUpdateMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          setTimeout(() => {
            refetch();
            navigate(PAGES.dashboard);
          }, 3000);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("error", "Update tax failed. Please try again.");
      }
    },
  });

  //Show spinner if data not loaded
  if (isProfileLoading) {
    return (
      <div className="loading-state">
        <div className="loading"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Card>
        <Card.Header className="py-4">
          <h5>Profile Settings</h5>
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="basicinfo"
            id="uncontrolled-tab-example"
            className="customtabs mb-2"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="basicinfo" title="Basic Info">
              <Form onSubmit={basicInfoFormik.handleSubmit}>
                <Row>
                  <Col md={5} xl={4} className="view_profile mb-3 ">
                    <div className="user_info text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        {isImageUploading ? (
                          <div className="profilePicLoadingView">
                            <Spinner animation="border" role="status" />
                          </div>
                        ) : (
                          <img
                            src={
                              profilePic
                                ? `${config.IMAGES_URL}/${profilePic}`
                                : DummyUser
                            }
                            // src={DummyUser}
                            className="rounded-circle img-fluid profilepic"
                            alt=""
                          />
                        )}
                      </div>
                      <label
                        htmlFor="uploadprofileimg"
                        className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                      >
                        Choose Picture
                      </label>
                      <input
                        type="file"
                        id="uploadprofileimg"
                        className="d-none"
                        onChange={handleUploadImage}
                      />
                    </div>
                  </Col>
                  <Col md={7} xl={8}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            name="name"
                            value={basicInfoFormik.values.name}
                            onChange={basicInfoFormik.handleChange}
                            onBlur={basicInfoFormik.handleBlur}
                            isInvalid={
                              !!basicInfoFormik.errors.name &&
                              basicInfoFormik.touched.name
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {basicInfoFormik.errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email address"
                            name="email"
                            value={basicInfoFormik.values.email}
                            onChange={basicInfoFormik.handleChange}
                            onBlur={basicInfoFormik.handleBlur}
                            isInvalid={
                              !!basicInfoFormik.errors.email &&
                              basicInfoFormik.touched.email
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {basicInfoFormik.errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Phone No.</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter phone no."
                            name="phone"
                            value={basicInfoFormik.values.phone}
                            onChange={basicInfoFormik.handleChange}
                            onBlur={basicInfoFormik.handleBlur}
                            isInvalid={
                              !!basicInfoFormik.errors.phone &&
                              basicInfoFormik.touched.phone
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {basicInfoFormik.errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      className="btn btn-primary px-4 mt-3"
                      disabled={
                        isProfileUpdating ||
                        isImageUpdating ||
                        !basicInfoFormik.isValid ||
                        !basicInfoFormik.dirty
                      }
                    >
                      {isProfileUpdating || isImageUpdating
                        ? "Updating..."
                        : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="changepassword" title="Change Password">
              <Form onSubmit={passwordFormik.handleSubmit}>
                <Row>
                  <Col md={8} xl={9}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Current Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Current Password"
                            name="currentPassword"
                            value={passwordFormik.values.currentPassword}
                            onChange={passwordFormik.handleChange}
                            onBlur={passwordFormik.handleBlur}
                            isInvalid={
                              !!passwordFormik.errors.currentPassword &&
                              passwordFormik.touched.currentPassword
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {passwordFormik.errors.currentPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New Password"
                            name="newPassword"
                            value={passwordFormik.values.newPassword}
                            onChange={passwordFormik.handleChange}
                            onBlur={passwordFormik.handleBlur}
                            isInvalid={
                              !!passwordFormik.errors.newPassword &&
                              passwordFormik.touched.newPassword
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {passwordFormik.errors.newPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Confirm New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm New Password"
                            name="confirmPassword"
                            value={passwordFormik.values.confirmPassword}
                            onChange={passwordFormik.handleChange}
                            onBlur={passwordFormik.handleBlur}
                            isInvalid={
                              !!passwordFormik.errors.confirmPassword &&
                              passwordFormik.touched.confirmPassword
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {passwordFormik.errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      className="btn btn-primary px-4 mt-3"
                      disabled={
                        isPasswordUpdating ||
                        !passwordFormik.isValid ||
                        !passwordFormik.dirty
                      }
                    >
                      {isPasswordUpdating ? "Updating..." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="tax" title="Manage Tax">
              <Form onSubmit={taxFormik.handleSubmit}>
                <Row>
                  <Col md={8} xl={9}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>{`Tax in Percentage(%)`}</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter tax"
                            name="tax"
                            value={taxFormik.values.tax}
                            onChange={taxFormik.handleChange}
                            onBlur={taxFormik.handleBlur}
                            isInvalid={
                              !!taxFormik.errors.tax && taxFormik.touched.tax
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {taxFormik.errors.tax}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      className="btn btn-primary px-4 mt-3"
                      disabled={
                        isProfileUpdating ||
                        !taxFormik.isValid ||
                        !taxFormik.dirty
                      }
                    >
                      {isProfileUpdating ? "Updating..." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
