import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useForgotPasswordMutation } from "../../store/services/auth/auth";
import { showToast } from "../../utils/toast";
import { ToastContainer } from 'react-toastify';
import PAGES from "../../constants/pages";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPasswordMutate, { isLoading }] = useForgotPasswordMutation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email address"),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await forgotPasswordMutate({ email: values.email }).unwrap();
        if (res?.success) {
          showToast('success', res?.message);
          setTimeout(() => {
            navigate(PAGES.otp,{state:{email: values.email}})
          }, 1000);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("error", "Something went wrong. Please try again");
      }
    },
  });

  useEffect(() => {
    document.body.classList.add("loginbody");
    return () => {
      document.body.classList.remove("loginbody");
    };
  }, []);

  return (
    <Row className="justify-content-center">
      <ToastContainer />
      <Col lg={6} className="loginform">
        <div className="loginmain_div maxwidth500 mx-auto">
          <div className="headlogin_div mb-4">
            <h2>Forgot Password</h2>
            <p>
              Please enter your email address to receive an OTP for password
              reset.
            </p>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.email && formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" disabled={isLoading} className="btn btn-primary w-100 mt-3">
              {isLoading ? "Loading..." : "Proceed" }
            </Button>
            <p className="formfooter text-center mt-3">
              Back to <Link to="/">Login</Link>
            </p>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
