import React, { useState, useEffect } from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  useCreateSubCategoryMutation,
  useGetActiveCategoryListQuery,
  useSubCategoriesListQuery,
  useUpdateSubCategoryMutation,
} from "../../store/services/home/home";
import { showToast } from "../../utils/toast";
import NoResult from "./NoResult";
import { ToastContainer } from "react-toastify";
import instance from "../../utils/instance";
import { debounce } from "../../utils/common";

const SubcategoryModal = ({
  show,
  handleClose,
  title,
  setTitle,
  category,
  setCategory,
  handleSubmit,
  isEdit,
  data,
  loading,
  id,
}) => (
  <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>
        <h2 className="modalhead">{isEdit ? "Edit" : "Add"} Subcategory</h2>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3 form-group">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="form-group mb-3">
          <Form.Label>Category</Form.Label>
          <Form.Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="select">Select Category</option>
            {data?.map((cate) => (
              <option key={cate?._id} value={cate?._id}>
                {cate?.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Form>
      <Button
        className="btn btn-primary px-4 w-100"
        onClick={() => handleSubmit(id)}
      >
        {isEdit
          ? loading
            ? "Updating..."
            : "Update"
          : loading
          ? "Adding..."
          : "Add"}
      </Button>
    </Modal.Body>
  </Modal>
);

const DeleteModal = ({ show, handleClose, handleOk, loading }) => (
  <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
    <Modal.Body>
      <div className="modaldelete_div">
        <Icon className="delete_icon" icon="gg:close-o" />
        <h3>Are You Sure?</h3>
        <p>
          {loading
            ? "Loading..."
            : "You will not be able to recover the deleted record!"}
        </p>
      </div>
      <Button
        onClick={handleClose}
        className="btn btn-primary-outline px-4 me-3"
      >
        Cancel
      </Button>
      <Button className="btn btn-primary px-4 min_width110" onClick={handleOk}>
        Ok
      </Button>
    </Modal.Body>
  </Modal>
);

export default function Subcategory() {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [addCategoryMutate, { isLoading: subCategoryAdding }] =
    useCreateSubCategoryMutation();
  const [updateSubCategoryMutate, { isLoading: subCategoryUpdating }] =
    useUpdateSubCategoryMutation();
  const { data: categories } = useGetActiveCategoryListQuery();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const {
    data: subcategories,
    status,
    refetch,
  } = useSubCategoriesListQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search: search,
  });

  const isLoaded = status !== "pending";

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (subcategory) => {
    setTitle(subcategory.name);
    setCategory(subcategory.cat_id?._id);
    setSelectedSubcategoryId(subcategory._id);
    setShow2(true);
  };
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  useEffect(() => {
    if (subcategories?.body?.totalCount) {
      setTotalRows(subcategories?.body?.totalCount);
    }
  }, [subcategories]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => row?.name,
    },
    {
      name: "Category",
      selector: (row) => row?.cat_id?.name,
      sortable: false,
      cell: (row) => row?.cat_id?.name,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt).toDateString(),
      sortable: false,
    },
    {
      name: "Status",
      maxWidth: "180px",
      selector: (row) => (row.is_active === "1" ? "Active" : "Inactive"),
      sortable: false,
      cell: (row) => (
        <span
          className={
            row.is_active === "1" ? "Active" + " badge" : "Inactive" + " badge"
          }
        >
          {row.is_active === "1" ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnedit" onClick={() => handleShow2(row)}>
            <Icon icon="tabler:pencil" />
          </Link>
          <Link
            className="btndelete"
            onClick={() => {
              handleShow3();
              setSubCategoryId(row?._id);
            }}
          >
            <Icon icon="tabler:trash"></Icon>
          </Link>
          <Link
            className="category-status"
            onClick={() => {
              setIsStatusUpdating(true);
              handleUpdateStatus(row?._id, row.is_active);
              setCategory(row?.cat_id?._id);
              setSubCategoryId(row?._id);
            }}
          >
            <p>
              {subCategoryUpdating &&
              isStatusUpdating &&
              row?._id === subCategoryId
                ? "Updating..."
                : "Change Status"}
            </p>
          </Link>
        </>
      ),
    },
  ];

  // Handle add new category
  const handleAddCategory = async () => {
    if (!title || !category) {
      showToast("warning", "All fields are required!");
    } else {
      const body = {
        cat_id: category,
        name: title,
        // image_url: "/images/rithhvuw6uNf4Lc.png", //FIXME:make it dynamic if required
      };
      try {
        const res = await addCategoryMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          handleClose();
          setTitle("");
          setCategory("");
          setTimeout(() => {
            refetch();
          }, 300);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("success", "Something went wrong!. Please try again");
      }
    }
  };

  // Handle update category
  const handleUpdateCategory = async (id) => {
    if (!title || !category) {
      showToast("warning", "All fields are required!");
    } else {
      const body = {
        id,
        cat_id: category,
        name: title,
        image_url: "/images/rithhvuw6uNf4Lc.png", //FIXME:make it dynamic if required
      };
      try {
        const res = await updateSubCategoryMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          handleClose2();
          setTitle("");
          setCategory("");
          setTimeout(() => {
            refetch();
          }, 300);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("success", "Something went wrong!. Please try again");
      }
    }
  };

  // Handle active/in-active
  const handleUpdateStatus = async (id, status) => {
    const body = {
      id: id,
      is_active: status === "1" ? "0" : "1",
    };
    try {
      const res = await updateSubCategoryMutate(body).unwrap();
      if (res?.success) {
        setIsStatusUpdating(false);
        showToast("success", res?.message);
        handleClose2();
        refetch();
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      console.log("Error updating category", error);
    } finally {
      setIsStatusUpdating(false);
    }
  };

  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );

  //Handle delete sub-category
  const handleDeleteSubCategory = async () => {
    try {
      setLoading(true);
      const { data } = await instance.get(
        "/admin/removed-subcategory?id=" + subCategoryId
      );
      if (data?.success) {
        refetch();
        setLoading(false);
        handleClose3();
        showToast("success", data?.message);
      }
    } catch (error) {
      console.log("Error deleting category", error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Subcategories</h5>
          <Button
            className="btn btn-primary px-4 min_width140"
            onClick={() => {
              handleShow();
              setTitle("");
              setCategory("");
            }}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => {
                debouncedSearchTextValue(e);
                setSearchText(e.target.value);
              }}
            />
          </div>
          {subcategories?.body?.subcategories?.length ? (
            <DataTable
              columns={columns}
              data={subcategories?.body?.subcategories}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              responsive
              className="custom-table"
            />
          ) : (
            <div>
              {!isLoaded ? (
                <div className="loading-state-changed">
                  <div className="loading"></div>
                </div>
              ) : (
                <NoResult />
              )}
            </div>
          )}
        </Card.Body>
      </Card>

      <SubcategoryModal
        show={show}
        handleClose={handleClose}
        title={title}
        setTitle={setTitle}
        category={category}
        setCategory={setCategory}
        handleSubmit={handleAddCategory}
        isEdit={false}
        data={categories?.body}
        loading={subCategoryAdding}
      />

      <SubcategoryModal
        show={show2}
        handleClose={handleClose2}
        title={title}
        setTitle={setTitle}
        category={category}
        setCategory={setCategory}
        handleSubmit={handleUpdateCategory}
        isEdit={true}
        data={categories?.body}
        loading={subCategoryUpdating}
        id={selectedSubcategoryId}
      />

      <DeleteModal
        show={show3}
        handleClose={handleClose3}
        handleOk={handleDeleteSubCategory}
        loading={loading}
      />
    </React.Fragment>
  );
}
