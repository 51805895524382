const PAGES = {
    login: '/',
    forgotPassword: '/forgotPassword',
    otp: '/otp',
    resetPassword: '/resetPassword',
    dashboard: '/dashboard',
    users: '/users',
    addUser: '/addUser',
    editUser: '/editUser',
    viewUser: '/viewUser',
    allPages: '/allPages',
    contactQueries: '/contactQueries',
    reports: '/reports',
    subscriptionPackages: '/subscriptionPackages',
    dates: '/dates',
    dateDetail: '/dateDetail',
    conciergeRequests: '/conciergeRequests',
    viewRequests: '/viewRequests',
    viewPayment: '/viewPayment',
    userDateDetail: '/userDateDetail',
    category: '/category',
    subcategory: '/subcategory',
    profileSettings: '/profileSettings',
};

export const ROUTES = Object.values(PAGES);

export default PAGES;






