import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useVerifyOTPMutation } from "../../store/services/auth/auth";
import { showToast } from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import PAGES from "../../constants/pages";

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || "";
  const [verifyOTPMutate, { isLoading }] = useVerifyOTPMutation();

  useEffect(() => {
    document.body.classList.add("loginbody");
    return () => {
      document.body.classList.remove("loginbody");
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^\d+$/, "OTP must be a number")
        .length(6, "OTP must be 6 digits")
        .required("Please enter your OTP"),
    }),
    onSubmit: async (values) => {
      const body = {
        otp: values.otp,
        emailOrPhone: email,
      };
      try {
        const res = await verifyOTPMutate(body).unwrap();
        if (res?.success) {
          showToast("success", res?.message);
          setTimeout(() => {
            navigate(PAGES.resetPassword, { state: { email: email } });
          }, 1000);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("error", "Something went wrong. Please try again");
      }
    },
  });

  return (
    <Row className="justify-content-center">
      <ToastContainer />
      <Col lg={6} className="loginform">
        <div className="loginmain_div maxwidth500 mx-auto">
          <div className="headlogin_div mb-4">
            <h2>OTP Verification</h2>
            <p>
              Please enter the 6-digit verification code sent to your registered
              email address.
            </p>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="number"
                name="otp"
                placeholder="Enter your OTP"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                isInvalid={formik.touched.otp && !!formik.errors.otp}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.otp}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              className="btn btn-primary w-100 mt-3"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Proceed"}
            </Button>
            <p className="formfooter text-center mt-3">
              Back to <Link to="/">Login</Link>
            </p>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
