import React, { useState } from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  useAllPagesListQuery,
  useCreatePageMutation,
  useDeletePageQuery,
  useUpdatePageMutation,
} from "../../store/services/home/home";
import { Formik, Field, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { showToast } from "../../utils/toast";

const AllPages = () => {
  const { data: allPages, isLoading: allPagesLoading, status, refetch } = useAllPagesListQuery();
  const [createPageMutate, { isLoading: pageCreating }] = useCreatePageMutation();
  const [updatePageMutate, { isLoading: pageUpdating }] = useUpdatePageMutation();
  const [pageID, setPageID] = React.useState("");
  const [deletePage, setDeletePage] = React.useState(false);
  const { isLoading: pageDeleting } = useDeletePageQuery({ id: pageID }, { skip: !deletePage });
  const isLoaded = status === "pending" ? false : true;
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [searchText, setSearchText] = useState("");

  const toggleAddModal = () => setShow((prev) => !prev);
  const toggleEditModal = (page) => {
    setSelectedPage(page);
    setShowEditModal((prev) => !prev);
  };
  const toggleDeleteModal = (page) => {
    setSelectedPage(page);
    setShowDeleteModal((prev) => !prev);
  };

  const filteredData = allPages?.body?.filter(
    item => JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !== -1
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.title,
      sortable: false,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.updatedAt).toDateString(),
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnedit" onClick={() => { setPageID(row?._id); toggleEditModal(row); }}>
            <Icon icon="tabler:pencil" />
          </Link>
          {/* <Link className="btndelete" onClick={() => { setPageID(row?._id); toggleDeleteModal(row); }}>
            <Icon icon="tabler:trash" />
          </Link> */}
        </>
      ),
    },
  ];

  const AddPageSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleAddNewPage = async (values, { resetForm }) => {
    try {
      const res = await createPageMutate(values).unwrap();
      if (res.success) {
        showToast("success", res?.message);
        toggleAddModal();
        resetForm();
        refetch();
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      console.error("Error creating page", error);
      showToast("error", "Something went wrong. Please try again");
    }
  };

  const handleUpdatePage = async (values, { resetForm }) => {
    try {
      const res = await updatePageMutate({ id: pageID, ...values }).unwrap();
      // console.log('res :>> ', res);
      if (res.success) {
        showToast("success", res?.message);
        toggleEditModal();
        resetForm();
        refetch();
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      console.error("Error updating page", error);
      showToast("error", "Something went wrong. Please try again");
    }
  };

  const handleDeletePage = () => {
    setDeletePage(true);
    setTimeout(() => {
      refetch();
    }, 200);
    toggleDeleteModal();
  };

  if (!isLoaded) {
    return (
        <>
<ToastContainer />
        <div className="loading-state">
        <div className="loading"></div>
      </div>
        </>
      
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>All Pages</h5>
          {/* <Button className="btn btn-primary px-4 min_width140" onClick={toggleAddModal}>
            Add
          </Button> */}
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
      <Modal size="lg" show={show} onHide={toggleAddModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add Page</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ title: "", description: "" }}
            validationSchema={AddPageSchema}
            onSubmit={handleAddNewPage}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Title</Form.Label>
                  <Field
                    name="title"
                    type="text"
                    className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                    placeholder="Enter Title"
                  />
                  {touched.title && errors.title ? (
                    <div className="invalid-feedback">{errors.title}</div>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Description</Form.Label>
                  <Field name="description">
                    {({ field }) => (
                      <CKEditor
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "blockQuote",
                          "undo",
                          "redo"
                        ],
                      }}
                        editor={ClassicEditor}
                        data="<p></p>"
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange({
                            target: { name: field.name, value: data },
                          });
                        }}
                      />
                    )}
                  </Field>
                  {touched.description && errors.description ? (
                    <div className="invalid-feedback" style={{ display: 'block' }}>{errors.description}</div>
                  ) : null}
                </Form.Group>
                <Button type="submit" disabled={pageCreating} className="btn btn-primary px-4 w-100">
                  {pageCreating ? "Adding..." : "Add"}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={showEditModal} onHide={toggleEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Page</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title: selectedPage?.title || "",
              description: selectedPage?.description || "",
            }}
            validationSchema={AddPageSchema}
            onSubmit={handleUpdatePage}
            enableReinitialize
          >
            {({ errors, touched }) => (
              <FormikForm>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Title</Form.Label>
                  <Field
                    name="title"
                    type="text"
                    className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                    placeholder="Enter Title"
                  />
                  {touched.title && errors.title ? (
                    <div className="invalid-feedback">{errors.title}</div>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Description</Form.Label>
                  <Field name="description">
                    {({ field }) => (
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "blockQuote",
                            "undo",
                            "redo"
                          ],
                        }}
                        data={field.value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange({
                            target: { name: field.name, value: data },
                          });
                        }}
                      />
                    )}
                  </Field>
                  {touched.description && errors.description ? (
                    <div className="invalid-feedback">{errors.description}</div>
                  ) : null}
                </Form.Group>
                <Button type="submit" disabled={pageUpdating} className="btn btn-primary px-4 w-100">
                  {pageUpdating ? "Updating..." : "Update"}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal className="modal_Delete" show={showDeleteModal} onHide={toggleDeleteModal} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o" />
            <h3>Are You Sure?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={toggleDeleteModal} className="btn btn-primary-outline px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={handleDeletePage}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AllPages;
