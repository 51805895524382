import config from "../../../constants/config";
import { END_POINTS } from "../../../constants/endpoints";
import { api } from "../api";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => (
        {
          url: END_POINTS.login,
          method: "POST",
          body,
        }
      ),
    }),
    forgotPassword: build.mutation({
      query: (body) => (
        {
          url: END_POINTS.forgotPassword,
          method: "POST",
          body,
        }
      ),
    }),
    verifyOTP: build.mutation({
      query: (body) => (
        {
          url: END_POINTS.verifyOTP,
          method: "POST",
          body,
        }
      ),
    }),
    resetPassword: build.mutation({
      query: (body) => (
        {
          url: END_POINTS.resetPassword,
          method: "POST",
          body,
        }
      ),
    }),
    updateProfile: build.mutation({
      query: (body) => (
        {
          url: END_POINTS.updateProfile,
          method: "POST",
          body,
        }
      ),
    }),
    changePassword: build.mutation({
      query: (body) => (
        {
          url: END_POINTS.changePassword,
          method: "POST",
          body,
        }
      ),
    }),
    getAdminDetails: build.query({
      query: (body) => (
        {
          url: END_POINTS.adminProfile,
          method: "GET",
          body,
        }
      ),
    }),
    uploadFile: build.mutation({
      query: (body) => ({
        url: END_POINTS.uploadFile,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
  useUpdateProfileMutation,
  useGetAdminDetailsQuery,
  useChangePasswordMutation,
  useUploadFileMutation
} = authApi;
