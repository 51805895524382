import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import localStorage from "redux-persist/es/storage";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authToken: null,
    user: {},
    verified: false,
  },
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.authToken = payload.token;
      state.user = payload.user;
      state.verified = true;
      localStorage.setItem("authToken", payload.token);
    },
    updateProfileSuccess: (state, { payload }) => {
      state.user = payload;
    },
    logoutSuccess: (state, action) => {
      state.authToken = null;
      state.verified = false;
      state.user = {};
      localStorage.setItem("authToken", "");
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  updateProfileSuccess,
} = authSlice.actions;

export default authSlice.reducer;

export const useAuthSelector = () => {
  const { authToken, user, verified } = useSelector((state) => state.auth);
  return { authToken, user, verified };
};