import React, { useState } from "react";
import { Row, Col, Card, Button, Form, Modal, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useDateRequestListQuery } from "../../store/services/home/home";
import PAGES from "../../constants/pages";
import NoResult from "./NoResult";
import { debounce } from "../../utils/common";
export default function ConciergeRequests() {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const { data: datesRequestList, status } = useDateRequestListQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search:search
  });
  
  const isLoaded = status === "pending" ? false : true;
  const [Message, setMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [formFields, setFormFields] = useState([
    {
      title: "",
      location: "",
      date: "",
      time: "",
      amount: "",
      description: "",
    },
  ]);

  const handleAddFields = () => {
    setFormFields([
      ...formFields,
      {
        title: "",
        location: "",
        date: "",
        time: "",
        amount: "",
        description: "",
      },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
  };

  const handleSubmit = () => {
    // console.log(formFields);
    handleClose2();
  };

  React.useEffect(() => {
    if (datesRequestList?.body?.totalCount) {
      setTotalRows(datesRequestList?.body?.totalCount); // Assuming total is available in response
    }
  }, [datesRequestList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };
  const columns = [
    {
      name: "From",
      minWidth: "180px",
      selector: (row) => row.Fromname,
      sortable: false,
      cell: (row) => (
        <>
          {row?.sender_id?.name}
          <br />
          {row?.sender_id?.email}
        </>
      ),
    },
    {
      name: "With",
      minWidth: "180px",
      selector: (row) => row.Withname,
      sortable: false,
      cell: (row) => (
        <>
          {row?.receiver_id?.name}
          <br />
          {row?.receiver_id?.email}
        </>
      ),
    },
    {
      name: "Adventures",
      minWidth: "260px",
      flexWrap: "wrap",
      selector: (row) => row.Adventures,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Array.isArray(row?.adventures) ? (
            row?.adventures?.map((adventure, index) => (
              <span
                key={index}
                className="badge adventure_badge bg-secondary"
                style={{ margin: "2px" }}
              >
                {adventure?.name}
              </span>
            ))
          ) : (
            <span>No adventures</span>
          )}
        </div>
      ),
    },
    {
      name: "Location",
      minWidth: "140px",
      selector: (row) => row.Location,
      sortable: false,
      cell: (row) => row?.destination?.location,
    },
    {
      name: "Start Date",
      minWidth: "140px",
      selector: (row) => row.StartDate,
      sortable: false,
      cell: (row) => new Date(row?.dates[0]).toDateString(),
    },
    {
      name: "End Date",
      minWidth: "140px",
      selector: (row) => row.EndDate,
      sortable: false,
      cell: (row) => new Date(row?.dates[row?.dates.length - 1]).toDateString(),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link
            className="btnview"
            to={PAGES.viewRequests}
            state={{ data: row }}
          >
            <Icon icon="tabler:eye"></Icon>
          </Link>
        </>
      ),
    },
  ];

  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );


  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header className="py-4">
              <h5>Concierge Requests</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) =>{ debouncedSearchTextValue(e);      setSearchText(e.target.value);}}
                />
              </div>
              {datesRequestList?.body?.concierges?.length ? (
                <DataTable
                  columns={columns}
                  data={datesRequestList?.body?.concierges}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  responsive
                  className="custom-table"
                />
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="justify-content-center">
          <Modal.Title>
            <h2 className="modalhead">Reason</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter Here..."
                value={Message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button
              onClick={handleClose}
              className="btn btn-primary-outline px-4 me-3"
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary px-4 min_width110"
              onClick={handleClose}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={show2} onHide={handleClose2} centered>
        <Modal.Header className="justify-content-between">
          <Modal.Title>
            <h2 className="modalhead">Add Date</h2>
          </Modal.Title>
          <Button variant="primary" onClick={handleAddFields}>
            Add More
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {formFields.map((field, index) => (
              <div key={index}>
                <Row className="mb-3">
                  <Col md="6">
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" placeholder="Enter Title" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Location</Form.Label>
                      <Form.Control type="text" placeholder="Enter Location" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Date</Form.Label>
                      <Form.Control type="date" placeholder="Enter Date" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Time</Form.Label>
                      <Form.Control type="time" placeholder="Enter Time" />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control type="text" placeholder="Enter Amount" />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Enter Here..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {index > 0 && (
                  <div className="text-end mb-3">
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveFields(index)}
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </Form>
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <b>Subtotal</b>
                </td>
                <td>$100.00</td>
              </tr>
              <tr>
                <td>
                  <b>Tax</b>
                </td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>
                  <b>Total</b>
                </td>
                <td>$110.00</td>
              </tr>
            </tbody>
          </Table>
          <div className="text-center mt-3">
            <Button
              onClick={handleClose2}
              className="btn btn-primary-outline px-4 me-3"
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary px-4 min_width110"
              onClick={handleSubmit}
            >
              Send Invoice
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
