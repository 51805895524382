import React from "react";
import Sidebar from './Sidebar';
import Header from './Header';
import {
    Breadcrumb,
} from 'react-bootstrap';

const Layout = ({ children, title }) => {

    return (
        <>
            <div>
                <Header title={title}/>
                <Sidebar />
                <div className='mb-3 maincontent_div'>
                <div className="mainbreadcrumb d-block d-md-none mb-3"> 
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Pages</Breadcrumb.Item>
                        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Layout;