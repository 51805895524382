import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import { useDatesListQuery } from "../../store/services/home/home";
import { useSelector } from "react-redux";
import PAGES from "../../constants/pages";
import NoResult from "./NoResult";
import { debounce } from "../../utils/common";

export default function Dates() {
  const { user } = useSelector(state => state.auth);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [search, setSearch] = React.useState('');

  const { data: datesList, status } = useDatesListQuery({
    offset: currentPage === 0 ? 0 : currentPage - 1,
    limit: rowsPerPage,
    search:search
  });
  const isLoaded = status !== "pending";

  useEffect(() => {
    if (datesList?.body?.totalCount) {
      setTotalRows(datesList?.body?.totalCount);
    }
  }, [datesList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: 'By',
      minWidth: '180px',
      selector: row => row.Byname,
      sortable: false,
      cell: (row) => <>
        {row?.sender_id?.name}<br />
        {row?.sender_id?.email}
      </>
    },
    {
      name: 'With',
      minWidth: '180px',
      selector: row => row.Withname,
      sortable: false,
      cell: (row) => <>
        {row?.receiver_id?.name}<br />
        {row?.receiver_id?.email}
      </>
    },
    {
      name: 'Location',
      selector: row => row.Location,
      sortable: false,
      cell: (row) => row?.destination?.location,
    },
    {
      name: 'Start Date',
      selector: row => row?.dates[0],
      sortable: false,
      cell: (row) => new Date(row?.dates[0]).toDateString(),
    },
    {
      name: 'End Date',
      selector: row => row.EndDate,
      sortable: false,
      cell: (row) => new Date(row?.dates[row?.dates.length - 1]).toDateString(),
    },
    {
      name: "Adventures",
      minWidth: "260px",
      flexWrap: "wrap",
      selector: (row) => row.Adventures,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Array.isArray(row?.adventures) ? (
            row?.adventures?.map((adventure, index) => (
              <span
                key={index}
                className="badge adventure_badge bg-secondary"
                style={{ margin: "2px" }}
              >
                {adventure?.name}
              </span>
            ))
          ) : (
            <span>No adventures</span>
          )}
        </div>
      ),
    },
    {
      name: 'Amount',
      selector: row => row.Amount,
      sortable: false,
      cell: (row) => "$" + row.total,
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <Link className="btnview" to={PAGES.dateDetail} state={{ data: row }}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
        </>
      ),
    },
  ];

  const debouncedSearchTextValue = React.useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 300),
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-4">
              <h5>Dates</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) =>{ debouncedSearchTextValue(e);      setSearchText(e.target.value);}}
                />
              </div>
              {datesList?.body?.dates?.length ? (
                <DataTable
                  columns={columns}
                  data={datesList?.body?.dates}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  responsive
                  className="custom-table"
                />
              ) : (
                <div>
                  {!isLoaded ? (
                    <div className="loading-state-changed">
                      <div className="loading"></div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-primary-outline px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
