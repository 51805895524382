export const END_POINTS = {
    login: "/admin/admin-login",
    forgotPassword:'/admin/forgot-password',
    verifyOTP: "/auth/otp-verify",
    resetPassword:'/admin/reset-password',
    users:'/admin/users-list',
    userDetails:'/admin/user-details?id={}&type=',
    dashboard:"/admin/dashboard",
    updateProfile:'/admin/update-user',
    adminProfile:'/admin/get-profile-detail',
    changePassword:'/admin/change-password',
    uploadFile: "/auth/file-upload",
    categoriesList:'/admin/admin-category-list',
    updateCategory:'/admin/update-category',
    getActiveCategoryList: "/auth/category-list",
    getActiveSubCategoryList: "/auth/sub-category-list?cat_id=",
    createCategory:'/admin/create-category',
    subCategoriesList:'/admin/admin-subcategory-list',
    updateSubCategory:'/admin/update-subcategory',
    createSubCategory:'/admin/create-subcategory',
    reportList:'/admin/reports-list',
    reportDelete:'/admin/removed-report?id=',
    contactQueriesList:'/admin/contact-queries',
    contactQueriesDelete:'admin/removed-contact-queries?id=',
    datesList:'/admin/dates-list',
    dateRequestList:'/admin/concierge-requests',
    plansList:'/admin/plans-list',
    updatePlans:'/admin/update-plans',
    createUser:'/admin/create-user',
    updateUser:'/admin/update-user',
    allPagesList : '/admin/page-list',
    createPage:'/admin/create-page',
    updatePage:'/admin/update-page',
    deletePage:'/admin/removed-page?id=',
    conciergeApprove:'/admin/approved-concierge',
    conciergeReject:'/admin/reject-concierge',
    deleteUser:'/admin/user-delete-account'
  };
  