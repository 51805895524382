import React from "react";
import { 
  Row,
  Col,
  Card, 
  Table, 
  Button
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import PAGES from "../../constants/pages";
export default function UserDatedetail() {
  const location = useLocation();
  const params = location.state || {};
  const data = params?.data || {};
  const id = params?.id ||""
  const dateinfo = {
    Pickup: '$100.00',
    Drop: '$100.00',
    Dinner: '$1000.00',
    Total: '$1200.00',
  };  
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Date Details</h5>
          <Link className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"to={PAGES.viewUser} state={{id:id}} >Back</Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Row className="g-3 mb-4">
            <Col md="12">
              <div className="dateinvoice_box">
                <h4 className="dateinvoice_name">  { id !== data?.sender_id?._id
                          ? data?.sender_id?.name
                          : data?.receiver_id?.name}</h4>
                <h4 className="dateinvoice_email mb-2">{ id !== data?.sender_id?._id
                          ? data?.sender_id?.email
                          : data?.receiver_id?.email}</h4>
                <h4 className="dateinvoice_dtl mb-2">{data?.destination?.location}</h4>
                <h4 className="dateinvoice_dtl mb-2">Start Date: {new Date(data?.dates).toDateString()}</h4>
                <h4 className="dateinvoice_dtl">End Date: {new Date(data?.dates).toDateString()}</h4>
              </div>
            </Col>
          </Row>
          <Row className="g-3 mb-4">
            <Col md="4">
              <div className="datedtl_box">
                <h4>Pickup from {data?.pickup_address?.location_name}</h4>
                <p className="date_text">{data?.pickup_address?.time}</p>
                <p className="price_text">Rental car $ {data?.pickup_address?.car_rent}</p>
                <p className="date_desc">{data?.pickup_address?.descrtiption}</p>
              </div>
            </Col>
            <Col md="4">
              <div className="datedtl_box">
                <h4>{data?.destination_address?.title + " at " + data?.destination_address?.place}</h4>
                <p className="date_text">{data?.destination_address?.time}</p>
                <p className="price_text">Dinner for two $ {data?.destination_address?.price}</p>
                <p className="date_desc">{data?.destination_address?.descrtiption}</p>
              </div>
            </Col>
            <Col md="4">
              <div className="datedtl_box">
                <h4>Drop at {data?.drop_address?.location_name}</h4>
                <p className="date_text">{data?.drop_address?.time}</p>
                <p className="price_text">Rental car $ {data?.drop_address?.car_rent}</p>
                <p className="date_desc">{data?.drop_address?.descrtiption}</p>
              </div>
            </Col>
          </Row>
          <div>
            <h5 className="dtlmain_head">Receipt</h5>
            <Table responsive>
              <tbody>
                <tr>
                  <td><b>Pickup</b></td>
                  <td>${data.pickup_price}</td>
                </tr>
                <tr>
                  <td><b>Drop</b></td>
                  <td>${data.drop_price}</td>
                </tr>
                <tr>
                  <td><b>Dinner</b></td>
                  <td>${data.dinner_price}</td>
                </tr>
                <tr>
                  <td><b>Total</b></td>
                  <td>${data.total}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Button className="btn btn-primary px-4 mt-3 min_width140">Accept Concierge</Button>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}