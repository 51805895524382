import React, { useEffect } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useResetPasswordMutation } from "../../store/services/auth/auth";
import { showToast } from "../../utils/toast";
import { ToastContainer } from 'react-toastify';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || "";
  const [resetPasswordMutate, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    document.body.classList.add('loginbody');
    return () => {
      document.body.classList.remove('loginbody');
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Please enter a new password'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Please confirm your new password'),
    }),
    onSubmit: async (values) => {
      const body = {
        email,
        password: values.newPassword,
      };
      try {
        const res = await resetPasswordMutate(body).unwrap();
        if (res?.success) {
          showToast('success', res?.message);
          setTimeout(() => {
            navigate('/');
          }, 1000);
        } else {
          showToast("error", res?.message);
        }
      } catch (error) {
        showToast("error", "Something went wrong. Please try again");
      }
    },
  });

  return (
    <Row className="justify-content-center">
      <ToastContainer />
      <Col lg={6} className="loginform">
        <div className="loginmain_div maxwidth500 mx-auto">
          <div className="headlogin_div mb-4">
            <h2>Reset Password</h2>
            <p>Please enter the details below to reset your password.</p>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.newPassword && !!formik.errors.newPassword}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmNewPassword"
                placeholder="Enter confirm new password"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.confirmNewPassword && !!formik.errors.confirmNewPassword}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.confirmNewPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
              {isLoading ? "Loading..." : "Reset Password"}
            </Button>
            <p className="formfooter text-center mt-3">
              Back to <Link to="/">Login</Link>
            </p>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
