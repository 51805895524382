import React, { useState } from "react";
import { Row, Col, Form, Card, Button, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../assets/img/default.jpg";
import PAGES from "../../constants/pages";
import {
  useGetActiveCategoryListQuery,
  useGetActiveSubCategoryListQuery,
  useGetUsersDetailsQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../store/services/home/home";
import { validationSchemaWithEmail,validationSchemaWithPhone } from "../../constants/validations";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { Formik, Field, useFormikContext } from "formik";
import {
  convertDateFormat,
  formattedDate,
  uploadFiles,
} from "../../utils/common";
import { showToast } from "../../utils/toast";
import config from "../../constants/config";
import { ToastContainer } from "react-toastify";

const Availabilityvalue = [
  { value: "M", label: "Monday" },
  { value: "Tu", label: "Tuesday" },
  { value: "W", label: "Wednesday" },
  { value: "Th", label: "Thursday" },
  { value: "F", label: "Friday" },
  { value: "Sa", label: "Saturday" },
  { value: "Su", label: "Sunday" },
];

export default function Edituser() {
  const location = useLocation();
  const id = location.state || {};
  const navigate = useNavigate();
  const [updateUserMutate, { isLoading: userCreating }] =
    useUpdateUserMutation();

  const {
    data: userData,
    isLoading,
    status,
    refetch,
  } = useGetUsersDetailsQuery({ id: id, type: 1 }, { skip: !id?.id });
  const { refetch: refetchUsers } = useGetUsersQuery({
    offset: 0,
    limit: 10,
  });
  const [profilePic, setProfilePic] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [previousDays, setPreviousDays] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [categoryTags, setCategoryTags] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [subCategoryId, setSubCategoryId] = React.useState("");
  const { data: categoriesData } = useGetActiveCategoryListQuery();
  const [selectedAccordionItem, setSelectedAccordionItem] = React.useState();
  const { data: subCategory } = useGetActiveSubCategoryListQuery(
    { subCategoryId },
    { skip: !subCategoryId }
  );
  const userAdventure = userData?.body?.useradventure;
  const subcategoriesData = subCategory?.body;

  const user = userData?.body?.userdata;
  const date = user?.dob && convertDateFormat(user?.dob);

  React.useEffect(() => {
    if (userData?.body?.userdata?.availability !== undefined) {
      let days = userData?.body?.userdata?.availability;
      const allDays = days.split(",");
      // Map each day to its index in the array
      const selectedIndices = allDays.map((day) => {
        switch (day) {
          case "M":
            return { value: "M", label: "Monday" };
          case "Tu":
            return { value: "Tu", label: "Tuesday" };
          case "W":
            return { value: "W", label: "Wednesday" };
          case "Th":
            return { value: "Th", label: "Thursday" };
          case "F":
            return { value: "F", label: "Friday" };
          case "Sa":
            return { value: "Sa", label: "Saturday" };
          case "Su":
            return { value: "Su", label: "Sunday" };
          // handle invalid days if needed
        }
      });
      setPreviousDays(
        userData?.body?.userdata?.availability ? selectedIndices : []
      );
    }
    setProfilePic(userData?.body?.userdata?.profile_url);
  }, [userData]);

  const isLoaded = status !== "pending";

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #DADADA",
      boxShadow: "none",
      minHeight: "52px",
      borderRadius: "8px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#FC7912", // Change background color of the selected value container
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    try {
      setIsImageUploading(true);
      const result = await uploadFiles(file);
      if (result?.success) {
        setProfilePic(result?.body[0]);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    } finally {
      setIsImageUploading(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const formattedDOB = formattedDate(values.dob);

    const availabilityDays =
      previousDays?.length &&
      previousDays?.map((day) => {
        if (day !== null || day != undefined || day == "") {
          return day.value;
        }
      });

    let isAvailability = availabilityDays?.length ? availabilityDays : "";
    let isPreviousEmail =
      user?.email === values.email.toLowerCase() ? true : false;

    console.log("isPreviousEmail :>> ", isPreviousEmail);

    const bodyWithEmail = {
      id: userData?.body?.userdata?._id,
      name: values.name,
      email: values.email.toLowerCase(),
      phone: values.phoneNumber,
      profile_url: profilePic,
      dob: formattedDOB,
      gender: values.gender,
      preferences: values.interestedIn,
      location_name: values.location,
      latitude: values.latitude,
      longitude: values.longitude,
      about_yourself: values.bio,
      adventures: selectedData,
      availability: isAvailability,
      is_active: values.status,
    };

    const bodyWithoutEmail = {
      id: userData?.body?.userdata?._id,
      name: values.name,
      phone: values.phoneNumber,
      profile_url: profilePic,
      dob: formattedDOB,
      gender: values.gender,
      preferences: values.interestedIn,
      location_name: values.location,
      latitude: values.latitude,
      longitude: values.longitude,
      about_yourself: values.bio,
      adventures: selectedData,
      availability: isAvailability,
      is_active: values.status,
    };

    let body = isPreviousEmail ? bodyWithoutEmail : bodyWithEmail;
    try {
      const res = await updateUserMutate(body).unwrap();
      // console.log('res :>> ', res);
      if (res?.success) {
        showToast("success", res?.message);
        setTimeout(() => {
          refetch();
          refetchUsers();
          navigate(PAGES.users);
        }, 200);
      } else {
        showToast("error", res?.message);
      }
    } catch (error) {
      showToast("error", "Something went wrong! Please try again");
      console.error("Error: ", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAccordionChange = (item,e) => {
    if(e) {
      e.preventDefault();
    }
    if (item) {
      setSubCategoryId(item?._id);
    }
  };

  const handleTagPress = (e, tags) => {
    e.preventDefault();
    const existingIndex = selectedTags?.findIndex(
      (item) => item.id === tags._id
    );
    if (existingIndex === -1) {
      let _id = {
        id: tags._id,
        label: tags.name,
      };
      // If the item doesn't exist, add it to the array
      selectedTags.push(_id);
    } else {
      // If the item already exists, remove it from the array
      selectedTags?.splice(existingIndex, 1);
    }

    if (Array.isArray(tags)) {
      // Map selected tags to selectedData array
      const newSubCatIds = tags?.map((tag) => tag._id);
      const newData = newSubCatIds?.map((subCatId) =>
        // console.log({ subCatId }),
        ({
          cat_id: subCategoryId,
          sub_cat_id: subCatId,
        })
      );

      // Check if the cat_id and sub_cat_id pair already exists
      const filteredData = newData.filter(
        ({ cat_id, sub_cat_id }) =>
          !selectedData?.some(
            (item) => item.cat_id === cat_id && item.sub_cat_id === sub_cat_id
          )
      );

      // console.log('filteredData :>> ', filteredData);

      setSelectedData((prevData) => [...prevData, ...filteredData]);
    } else if (tags && typeof tags === "object") {
      // Check if the selected tag already exists in selectedData
      const selectedSubCatId = tags._id;
      const isTagSelected = selectedData?.some(
        (item) =>
          item.cat_id === subCategoryId && item.sub_cat_id === selectedSubCatId
      );

      if (isTagSelected) {
        // Remove the selected tag from selectedData
        setSelectedData((prevData) =>
          prevData?.filter(
            (item) =>
              !(
                item.cat_id === subCategoryId &&
                item.sub_cat_id === selectedSubCatId
              )
          )
        );
      } else {
        if (selectedData?.length) {
          // Add the selected tag to selectedData
          setSelectedData((prevData) => [
            ...prevData,
            { cat_id: subCategoryId, sub_cat_id: selectedSubCatId },
          ]);
        } else {
          // Add the selected tag to selectedData
          setSelectedData([
            { cat_id: subCategoryId, sub_cat_id: selectedSubCatId },
          ]);
        }
      }
    } else {
      console.error("tags is not an array or object:", tags);
    }
  };

  const handleAvailabilityChange = (e, days) => {
    e.preventDefault();
    e.preventDefault();
    const exists = previousDays.some((d) => d?.value === days?.value);
    const updatedDays = exists
      ? previousDays.filter((d) => d?.value !== days?.value)
      : [...previousDays, days];
    setPreviousDays(updatedDays);
  };

  React.useEffect(() => {
    if (subCategory && subCategory?.body && !isLoading) {
      const tags = subCategory?.body?.map((cat) => {
        return {
          // ...cat,TODO:Enable if need
          id: cat?._id,
          label: cat?.name,
        };
      });
      setCategoryTags(tags);
    }
  }, [subCategory, selectedAccordionItem, isLoading]);

  React.useEffect(() => {
    const extractedData = userAdventure?.map((item) => ({
      id: item?.sub_cat_id?._id,
      label: item?.sub_cat_id?.name,
    }));
    setSelectedTags(extractedData);
    const previosData = userAdventure?.map((item) => ({
      cat_id: item?.cat_id?._id,
      sub_cat_id: item?.sub_cat_id?._id,
    }));

    setSelectedData(previosData);
  }, [userAdventure]);

  const EditUserFormValues = () => {
    const { values, setFieldValue } = useFormikContext();

    React.useEffect(() => {
      setFieldValue("selectedCategories", selectedData);
    }, [selectedData]);

    return null;
  };

  React.useEffect(() => {
    handleAccordionChange(categoriesData?.body[0])
  },[categoriesData])

  if (!isLoaded) {
    return (
      <>
        <div className="loading-state">
          <div className="loading"></div>
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Card.Body>
        <Row className="mt-3">
          <Col md={5} xl={4} className="view_profile mb-3 ">
            <div className="user_info text-center">
              <div className="d-flex align-items-center justify-content-center">
                {isImageUploading ? (
                  <div className="profilePicLoadingView">
                    <Spinner animation="border" role="status" />
                  </div>
                ) : (
                  <img
                    src={
                      profilePic ? `${config.IMAGES_URL}/${profilePic}` : Avatar
                    }
                    className="rounded-circle img-fluid profilepic"
                    alt=""
                  />
                )}
              </div>
              <label
                htmlFor="uploadprofileimg"
                className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
              >
                Choose Picture
              </label>
              <input
                type="file"
                id="uploadprofileimg"
                className="d-none"
                onChange={handleUploadImage}
              />
            </div>
          </Col>
          <Col md={7} xl={8}>
            <Formik
              initialValues={{
                name: user?.name ?? "",
                email: user?.email ?? "",
                phoneNumber: user?.phone ?? "",
                gender: user?.gender ?? "",
                interestedIn: user?.preferences ?? "",
                dob: date ?? "",
                location: user?.location_name ?? "",
                latitude: user?.location?.coordinates[0] ?? "",
                longitude: user?.location?.coordinates[1],
                bio: user?.about_yourself ?? "",
                status: userData?.body?.userdata?.is_active ?? "select",
                selectedCategories: [],
              }}
              validationSchema={user?.email ? validationSchemaWithEmail : validationSchemaWithPhone}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <EditUserFormValues />
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Name</Form.Label>
                        <Field
                          name="name"
                          type="text"
                          placeholder="Enter name"
                          className={`form-control ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                          value={values.name}
                          onChange={handleChange}
                        />
                        {touched.name && errors.name ? (
                          <div className="invalid-feedback">{errors.name}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Enter email address"
                          className={`form-control ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          value={values.email}
                          onChange={handleChange}
                        />
                        {touched.email && errors.email ? (
                          <div className="invalid-feedback">{errors.email}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Field
                          name="phoneNumber"
                          type="text"
                          placeholder="Enter phone number"
                          className={`form-control ${
                            touched.phoneNumber && errors.phoneNumber
                              ? "is-invalid"
                              : ""
                          }`}
                          value={values.phoneNumber}
                          onChange={handleChange}
                        />
                        {touched.phoneNumber && errors.phoneNumber ? (
                          <div className="invalid-feedback">
                            {errors.phoneNumber}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>DOB</Form.Label>
                        <Field
                          name="dob"
                          type="date"
                          placeholder={"Enter DOB"}
                          className={`form-control ${
                            touched.dob && errors.dob ? "is-invalid" : ""
                          }`}
                          value={values.dob}
                          onChange={handleChange}
                        />
                        {touched.dob && errors.dob ? (
                          <div className="invalid-feedback">{errors.dob}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Field
                          name="gender"
                          as="select"
                          className={`form-select ${
                            touched.gender && errors.gender ? "is-invalid" : ""
                          }`}
                          value={values.gender}
                          onChange={handleChange}
                        >
                          <option value="select">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Field>
                        {touched.gender && errors.gender ? (
                          <div className="invalid-feedback">
                            {errors.gender}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Interested In</Form.Label>
                        <Field
                          name="interestedIn"
                          as="select"
                          className={`form-select ${
                            touched.interestedIn && errors.interestedIn
                              ? "is-invalid"
                              : ""
                          }`}
                          value={values.interestedIn}
                          onChange={handleChange}
                        >
                          <option value="select">Select</option>
                          <option value="men">Male</option>
                          <option value="women">Female</option>
                        </Field>
                        {touched.interestedIn && errors.interestedIn ? (
                          <div className="invalid-feedback">
                            {errors.interestedIn}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Location</Form.Label>
                        <ReactGoogleAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                          onPlaceSelected={async (place) => {
                            setFieldValue(
                              "location",
                              place?.formatted_address || ""
                            );
                            setFieldValue(
                              "latitude",
                              place?.geometry?.location?.lat() || ""
                            );
                            setFieldValue(
                              "longitude",
                              place?.geometry?.location?.lng() || ""
                            );
                          }}
                          defaultValue={values.location}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("location", e.target.value);
                          }}
                          options={{
                            types: ["geocode", "establishment"],
                            fields: ["formatted_address", "geometry.location"],
                          }}
                          className={`form-control ${
                            touched.location && errors.location
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {touched.location && errors.location ? (
                          <div className="invalid-feedback">
                            {errors.location}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Bio</Form.Label>
                        <Field
                          name="bio"
                          as="textarea"
                          rows={5}
                          placeholder="Enter Bio"
                          className={`form-control ${
                            touched.bio && errors.bio ? "is-invalid" : ""
                          }`}
                          value={values.bio}
                          onChange={handleChange}
                        />
                        {touched.bio && errors.bio ? (
                          <div className="invalid-feedback">{errors.bio}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Adventures</Form.Label>
                      <div className="accordion" id="accordionExample">
                        {categoriesData?.body?.length > 0 &&
                          categoriesData.body.map((category, index) => {
                            const isFirstItem = index === 0; // Check if it is the first item

                            return (
                              <div key={index} className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}`}
                                >
                                  <button
                                    onClick={(e) =>
                                      handleAccordionChange(category,e,)
                                    }
                                    className={`accordion-button ${
                                      isFirstItem ? "" : "collapsed"
                                    }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={
                                      isFirstItem ? "true" : "false"
                                    }
                                    aria-controls={`collapse${index}`}
                                  >
                                    {category.name}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${index}`}
                                  className={`accordion-collapse collapse ${
                                    isFirstItem ? "show" : ""
                                  }`}
                                  aria-labelledby={`heading${index}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    {subcategoriesData?.length &&
                                      subcategoriesData.map(
                                        (subcategory, subIndex) => {
                                          const isSelected = selectedData?.some(
                                            (item) =>
                                              item.sub_cat_id ===
                                              subcategory._id
                                          );

                                          return (
                                            <button
                                              type="button"
                                              style={{
                                                backgroundColor: isSelected
                                                  ? "#FC7912"
                                                  : "#fff",
                                                border: "1px solid #FC7912",
                                                margin: "5px",
                                                borderRadius: "30px",
                                                padding: "5px",
                                                fontSize: "12px",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                color: "#000",
                                              }}
                                              key={subIndex}
                                              onClick={(e) =>
                                                handleTagPress(e, subcategory)
                                              }
                                              className="customButton"
                                            >
                                              {subcategory?.name}
                                            </button>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {touched.selectedCategories?.length < 1 && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.selectedCategories}
                        </div>
                      )}
                    </Form.Group>
                    <Col lg={12}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Availability</Form.Label>
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`headingAvailability`}
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseAvailability`}
                                aria-expanded="true"
                                aria-controls={`collapseAvailability`}
                              >
                                Availability
                              </button>
                            </h2>
                            <div
                              id={`collapseAvailability`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`headingAvailability`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {Availabilityvalue?.length &&
                                  Availabilityvalue?.map((days, index) => {
                                    const isSelected = previousDays?.some(
                                      (item) => item?.value === days?.value
                                    );

                                    return (
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: isSelected
                                            ? "#FC7912"
                                            : "#fff",
                                          border: "1px solid #FC7912",
                                          margin: "5px",
                                          borderRadius: "30px",
                                          padding: "5px",
                                          fontSize: "12px",
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                          color: "#000",
                                        }}
                                        key={index}
                                        onClick={(e) =>
                                          handleAvailabilityChange(e, days)
                                        }
                                        className="customButton"
                                      >
                                        {days?.label}
                                      </button>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group mb-3">
                        <Form.Label>Status</Form.Label>
                        <Field
                          name="status"
                          as="select"
                          className={`form-select ${
                            touched.status && errors.status ? "is-invalid" : ""
                          }`}
                          value={values.status}
                          onChange={handleChange}
                        >
                          <option value="select">Select Status</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </Field>
                        {touched.status && errors.status ? (
                          <div className="invalid-feedback">
                            {errors.status}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    type="submit"
                    className="btn btn-primary px-4 mt-3 min_width140"
                    disabled={userCreating}
                  >
                    {userCreating ? "Saving..." : "Save"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card.Body>
    </React.Fragment>
  );
}
