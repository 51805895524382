import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

export default function NotFound() {
  // useEffect(() => {
  //   document.body.classList.add("loginbody");
  //   return () => {
  //     document.body.classList.remove("loginbody");
  //   };
  // }, []);

  return (
    <main class="error-page">
      <div class="container">
        <div class="eyes">
          <div class="eye">
            <div class="eye__pupil eye__pupil--left"></div>
          </div>
          <div class="eye">
            <div class="eye__pupil eye__pupil--right"></div>
          </div>
        </div>

        <div class="error-page__heading">
          <h1 class="error-page__heading-title">Looks like you're lost</h1>
          <p class="error-page__heading-description">404 error</p>
        </div>

        <a
          class="error-page__button"
          href="#"
          aria-label="back to home"
          title="back to home"
        >
          back to home
        </a>
      </div>
      <button class="color-switcher" data-theme-color-switch>
        &#127769;
      </button>
    </main>
  );
}
